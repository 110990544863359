import React, {createContext, useEffect, useMemo, useState} from 'react';
import {
    getShadowReportMetadata,
    IShadowReportMetadata,
    SHADOW_REPORT_METADATA_UPDATED_EVENT_NAME, ShadowReportMetadataFormFields
} from '../storage/ShadowReportMetadataSheet';

interface IShadowReportMetadataContext {
    shadowReportMetadata: IShadowReportMetadata;
    shadowReportMetadataFormFieldsAssigned: boolean;
}

export const ShadowReportMetadataContext = createContext<IShadowReportMetadataContext>({
    shadowReportMetadata: null,
    shadowReportMetadataFormFieldsAssigned: true,
});

interface IShadowReportMetadataEditingContext {
    shadowReportMetadataEditing: boolean;
    setShadowReportMetadataEditing: (isEditing: boolean) => void;
}

export const ShadowReportMetadataEditingContext = createContext<IShadowReportMetadataEditingContext>({
    shadowReportMetadataEditing: false,
    setShadowReportMetadataEditing: () => {},
});

export function ShadowReportMetadataProvider({children}: { children: React.ReactNode }) {
    const [shadowReportMetadata, setShadowReportMetadata] = useState<IShadowReportMetadata>(null);
    const [shadowReportMetadataEditing, setShadowReportMetadataEditing] = useState<boolean>(false);

    const shadowReportMetadataFormFieldsAssigned = useMemo<boolean>(() => {
        if (!shadowReportMetadata) {
            return false;
        }
        for (let key of ShadowReportMetadataFormFields) {
            if (!shadowReportMetadata[key]) {
                return false;
            }
        }
        return true;
    }, [shadowReportMetadata]);

    useEffect(() => {
        const listener = (event: CustomEvent) => {
            setShadowReportMetadata(event.detail);
        };
        document.addEventListener(SHADOW_REPORT_METADATA_UPDATED_EVENT_NAME, listener);

        getShadowReportMetadata()
            .then(shadowReportMetadata => setShadowReportMetadata(shadowReportMetadata));

        return () => {
            document.removeEventListener(SHADOW_REPORT_METADATA_UPDATED_EVENT_NAME, listener);
        }
    }, [])

    const shadowReportMetadataContextValue = useMemo<IShadowReportMetadataContext>(() => ({
        shadowReportMetadata,
        shadowReportMetadataFormFieldsAssigned,
    }), [shadowReportMetadata, shadowReportMetadataFormFieldsAssigned]);

    const shadowReportMetadataEditingContextValue = useMemo<IShadowReportMetadataEditingContext>(() => ({
        shadowReportMetadataEditing,
        setShadowReportMetadataEditing,
    }), [shadowReportMetadataEditing, setShadowReportMetadataEditing]);

    return (
        <ShadowReportMetadataEditingContext.Provider value={shadowReportMetadataEditingContextValue}>
            <ShadowReportMetadataContext.Provider value={shadowReportMetadataContextValue}>{children}</ShadowReportMetadataContext.Provider>
        </ShadowReportMetadataEditingContext.Provider>
    );
}
