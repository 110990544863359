import React, {useCallback} from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Container,
    FormField,
    Header,
    Input,
    SpaceBetween,
    Spinner,
    StatusIndicator,
    StatusIndicatorProps
} from "@amzn/awsui-components-react";
import {fetchEnhancedReport} from "./api.helper";
import {CONTEXT_SHEET_NAME, extractRulesSheetAndContextSheet, renameFirstWorksheet} from "./utils";
import {copyRulesSheetToWorkbook} from "./rulesSheetProcessor";
import {buildContextMap, mergeContextDataIntoWorkbook} from "./contextSheetProcessor";

const UUID_REGEX = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
const EXECUTION_ID_ERROR_TEXT = "Please enter a valid Execution ID. It should be a unique identifier, consisting of 32 characters and hyphens (e.g., 550e8400-e29b-41d4-a716-446655440000). This ID can typically be found in the Shadow Report name.";

// Define the validation schema
const schema = yup.object().shape({
    executionId: yup
        .string()
        .required('Execution ID is required')
        .matches(UUID_REGEX, EXECUTION_ID_ERROR_TEXT)
});

export enum EnhancedReportStatus {
    NONE,
    FETCHING,
    NOT_FOUND,
    SUCCEEDED,
    REPORT_FETCHING_FAILED,
    POST_PROCESSING_FAILED
}

interface FormInputs {
    executionId: string;
}

interface EnhancedReportFetcherProps {
    shadowReportName?: string;
    contextDiffsExit?: boolean;
    onReportChange: () => void;
}

export const EnhancedReportFetcher: React.FunctionComponent<EnhancedReportFetcherProps> = ({ shadowReportName = '', contextDiffsExit = false ,onReportChange}) => {
    const extractExecutionId = useCallback((filename: string) => {
        const match = filename.match(/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/i);
        return match && match[1] ? match[1] : '';
    }, []);

    const initialExecutionId = extractExecutionId(shadowReportName);

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm<FormInputs>({
        defaultValues: {
            executionId: initialExecutionId
        },
        resolver: yupResolver(schema)
    });

    const [status, setStatus] = React.useState<EnhancedReportStatus>(EnhancedReportStatus.NONE);

    const submit = async (data: FormInputs) => {
        try {
            // Initial state - fetching
            setStatus(EnhancedReportStatus.FETCHING);

            // Process report
            const reportStatus = await fetchAndProcessReport(data.executionId);

            // Update status based on processing result
            setStatus(reportStatus)

            // Notify parent component of the report change (new added Diffs columns), so we can update available clustering type and disable the fetching button
            if (reportStatus === EnhancedReportStatus.SUCCEEDED) {
                onReportChange();
            }
        } catch (error) {
            console.error('Unexpected error during report processing:', error);
            setStatus(EnhancedReportStatus.POST_PROCESSING_FAILED);
        }
    };

    const fetchAndProcessReport = async (executionId: string): Promise<EnhancedReportStatus> => {
        // Fetch the report
        const result = await fetchEnhancedReport(executionId);

        // Handle fetch errors
        if (result.error) {
            return result.error.code === 404
                ? EnhancedReportStatus.NOT_FOUND
                : EnhancedReportStatus.REPORT_FETCHING_FAILED;
        }

        if (!result.data?.url) {
            return EnhancedReportStatus.REPORT_FETCHING_FAILED;
        }

        // Process and apply additional context data
        try {
            // Extract RULES and CONTEXT sheet from downloaded enhanced report
            const { rulesSheet, contextSheet } = await extractRulesSheetAndContextSheet(result.data.url);

            await renameFirstWorksheet(CONTEXT_SHEET_NAME);

            // Copy RULES sheet to the Vanilla Shadow Report
            await copyRulesSheetToWorkbook(rulesSheet);

            // ContextMap Key: TAR + TR; Value: RuleFileDiffs, RuleDiffs
            const contextMap = buildContextMap(contextSheet);

            // Merge [RuleFileDiffs, RuleDiffs] to the Vanilla Shadow Report using ContextMap
            const contextMergingStatus = await mergeContextDataIntoWorkbook(contextMap);

            return contextMergingStatus.success
                ? EnhancedReportStatus.SUCCEEDED
                : EnhancedReportStatus.POST_PROCESSING_FAILED;
        } catch (error) {
            console.error('Error during sheet processing:', error);
            return EnhancedReportStatus.POST_PROCESSING_FAILED;
        }
    };

    const getStatusMessage = (): { type: StatusIndicatorProps.Type; message: string } | null => {
        switch (status) {
            case EnhancedReportStatus.SUCCEEDED:
                return { type: 'success', message: 'Report fetched successfully' };
            case EnhancedReportStatus.NOT_FOUND:
                return { type: 'warning', message: 'Report not found. Please try again later' };
            case EnhancedReportStatus.REPORT_FETCHING_FAILED:
                return { type: 'error', message: 'Failed to fetch enhanced report' };
            case EnhancedReportStatus.POST_PROCESSING_FAILED:
                return { type: 'error', message: 'Failed to process enhanced report' };
            default:
                return null;
        }
    };

    return (
        <Container header={<Header>1a. Enhanced Report Fetching</Header>}>
            <form onSubmit={handleSubmit(submit)}>
                <SpaceBetween direction="vertical" size="s">
                    <FormField
                        label="Execution ID"
                        errorText={errors.executionId?.message}
                    >
                        <Controller
                            name="executionId"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    onChange={e => field.onChange(e.detail.value)}
                                    placeholder="Enter Execution ID"
                                />
                            )}
                        />
                    </FormField>

                    <Button
                        variant="primary"
                        formAction="submit"
                        disabled={status === EnhancedReportStatus.FETCHING || status === EnhancedReportStatus.SUCCEEDED || contextDiffsExit}
                    >
                        {status === EnhancedReportStatus.FETCHING ? (
                            <Spinner />
                        ) : (
                            'Fetch Report'
                        )}
                    </Button>

                    {getStatusMessage() && (
                        <StatusIndicator type={getStatusMessage()!.type}>
                            {getStatusMessage()!.message}
                        </StatusIndicator>
                    )}
                </SpaceBetween>
            </form>
        </Container>
    );
};
