import * as React from "react";
import {fetchS3ResourceSelectorObjects, getPresignedUrl} from "../Utils";
import {
    Alert,
    ButtonDropdown, Flashbar, FlashbarProps,
    FormField, Popover,
    S3ResourceSelector,
    StatusIndicator
} from "@amzn/awsui-components-react";
import {Button, SpaceBetween} from "@amzn/awsui-components-react/polaris";
import {useState} from "react";
import {buildMetric} from "../../../../metric/metric";
import {validateAndPublishMetric} from "@amzn/tax-platform-console-metrics";
import {enUS} from "./constants";

const S3_BUCKET_NAME = "tax-shadow-report-snapshots-bucket-prod";
const S3_URI_PREFIX = `s3://${S3_BUCKET_NAME}/`;

function SelfDismissibleAlert(props) {
    const [visible, setVisible] = useState(true);
    return (visible ?
        <Alert {...props} dismissible={true} onDismiss={() => setVisible(false)}/> : null);
}

export function DownloadSnapshot() {
    const [flashbarItems, setFlashbarItems]
        = useState<ReadonlyArray<FlashbarProps.MessageDefinition>>([]);
    const [validationError, setValidationError] = useState("");
    const [fetchError, setFetchError] = useState(null);
    const [resource, setResource] = React.useState({
        uri: ""
    });
    const [isLoading, setIsLoading] = useState(false);

    const wrapWithErrorHandler = (callback) => {
        return (...args) => {
            setFetchError(null);
            // intercept the error to display it and then propagate to the component
            return callback(...args).catch(error => {
                setFetchError(error.message);
                throw error;
            });
        };
    }

    const onItemClick = async ({detail}) => {
        setIsLoading(true);
        const objectKey = resource.uri.substring(S3_URI_PREFIX.length);
        const metric = await buildMetric("SnapshotDownload");
        metric.addProperty("ObjectKey", objectKey);
        try {
            const preSignedUrl = await getPresignedUrl(objectKey);
            switch (detail.id) {
                case "url":
                    metric.addProperty("DownloadType", "PresignedUrl");
                    setFlashbarItems([{
                        type: "success",
                        header: "Download URL is ready",
                        content: "The URL is valid for the next 7 days.",
                        action:
                            <Popover dismissButton={false} position="top" size="small" triggerType="custom"
                                     content={<StatusIndicator type="success">URL copied</StatusIndicator>}>
                                <Button
                                    iconName="copy"
                                    onClick={() => navigator.clipboard.writeText(preSignedUrl)}
                                >
                                    Copy URL
                                </Button>
                            </Popover>,
                        dismissible: true,
                        dismissLabel: "Dismiss message",
                        onDismiss: () => setFlashbarItems([]),
                    }]);
                    break;
                default:
                    metric.addProperty("DownloadType", "Download");
                    setFlashbarItems([{
                        type: "success",
                        header: "Download started",
                        dismissible: true,
                        dismissLabel: "Dismiss message",
                        onDismiss: () => setFlashbarItems([]),
                    }]);
                    window.open(preSignedUrl);
                    break;
            }
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : String(error);
            metric.addProperty("ErrorMessage", errorMessage.substring(0, 256));
            setFlashbarItems([{
                type: "error",
                header: "Request failed",
                content: errorMessage,
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: () => setFlashbarItems([]),
            }]);
        } finally {
            validateAndPublishMetric(metric);
            setIsLoading(false);
        }
    };

    return (
        <SpaceBetween size="xs">
            <Flashbar items={flashbarItems}/>
            <FormField
                errorText={validationError}
            >
                <S3ResourceSelector
                    i18nStrings={enUS}
                    alert={
                        fetchError && (
                            <SelfDismissibleAlert type="error" header="Data fetching error">
                                {fetchError}
                            </SelfDismissibleAlert>
                        )
                    }
                    onChange={({detail}) => {
                        setValidationError(detail.errorText ? detail.errorText : "");
                        setResource(detail.resource);
                    }}
                    resource={resource}
                    objectsIsItemDisabled={item => item.IsFolder}
                    fetchBuckets={() => Promise.resolve([{
                        Name: S3_BUCKET_NAME
                    }])}
                    fetchObjects={wrapWithErrorHandler(fetchS3ResourceSelectorObjects)}
                    fetchVersions={() => Promise.resolve(null)}
                    selectableItemsTypes={["objects"]}
                />
            </FormField>
            <ButtonDropdown
                variant="primary"
                disabled={validationError !== "" || resource.uri === ""}
                items={[
                    {text: "Download", id: "download"},
                    {text: "Share with URL", id: "url"}
                ]}
                onItemClick={onItemClick}
                loading={isLoading}
            >
                Actions
            </ButtonDropdown>
        </SpaceBetween>
    )
}