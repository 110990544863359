import jwtDecode from "jwt-decode";
import {stringify} from "query-string";
import sentryFetch from "@amzn/sentry-fetch";
import { aliasStorageEntry } from "../storage/StorageEntries";

export enum ErrorHandlingStrategy {
    THROW_IMMEDIATELY = 'THROW_IMMEDIATELY',
    DEFER_TO_CALLER = 'DEFER_TO_CALLER'
}

const DEFAULT_SENTRY_FETCH_OPTIONS = {
    credentials: "include",
    sentryOptions: {
        debug: true,
        followMidwayStepUp: true,
    },
};

const generateNonce = (): string => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

export const isMac = (): boolean => {
    return navigator.userAgent.indexOf("Macintosh") !== -1;
}

export const midwayFetch = async (
    url: string,
    options?: Record<string, unknown>,
    strategy: ErrorHandlingStrategy = ErrorHandlingStrategy.THROW_IMMEDIATELY
): Promise<Response> => {
    const newOptions = JSON.parse(JSON.stringify({...DEFAULT_SENTRY_FETCH_OPTIONS, ...options}));
    if (isMac()) {
        newOptions.sentryOptions.followMidwayStepUpOptions = {
          mode: "modal",
        };
    }
    const response = await sentryFetch(url, newOptions);
    await setAliasIfNeeded();
    if (!response.ok) {
        if (strategy === ErrorHandlingStrategy.THROW_IMMEDIATELY) {
            throw new Error(await response.text());
        }
        // If DEFER_TO_CALLER, we just return the response without throwing
    }
    return response;
}

const getUserAlias = async (): Promise<string> => {
    const token = await getAuthenticationToken();
    const data: any = jwtDecode(token);
    if (!data.sub) {
        throw new Error("Midway JWT sub is empty");
    }
    return data.sub;
}

const getAuthenticationToken = async (): Promise<string> => {
    const queryString = stringify({
        scope: "openid",
        response_type: "id_token",
        client_id: window.location.origin,
        redirect_uri: document.location.href,
        nonce: generateNonce(),
        sentry_handler_version: "MidwayNginxModule-1.3-1",
    });
    const url = `https://midway-auth.amazon.com/SSO?${queryString}`;
    const response = await fetch(url, {credentials: "include"});
    if (!response.ok) {
        throw new Error("Midway error");
    }
    const token = await response.text();
    if (!token) {
        throw new Error("Midway token is empty");
    }
    return token;
}

const setAliasIfNeeded = async (): Promise<void> => {
    const isAliasSet = await aliasStorageEntry.isSet();
    if (isAliasSet) {
        return;
    }

    try {
        const alias = await getUserAlias();
        console.log(`Saving alias: ${alias}.`);
        await aliasStorageEntry.set(alias);
    } catch (error) {
        console.log("Unable to save alias. Erorr: ", error);
    }
}
