import React, { useState } from "react";
import { useContext } from "react";
import { ShadowReportMetadataContext } from "../../../contexts/ShadowReportMetadataContext";
import { ShadowReportMetadataEntries, ShadowReportRegions } from "../../../storage/ShadowReportMetadataSheet";
import { Alert, Button, Container, Header, SpaceBetween, Spinner, StatusIndicator, StatusIndicatorProps } from "@amzn/awsui-components-react";
import { completeMultipartUpload, getPresignedUrls, initiateMultipartUpload } from "../SnapshotButton/Utils";

enum ContextGenerationStatus {
    NONE,
    UPLOADING,
    SUCCEEDED,
    FAILED,
}

const ROWS_PER_MULTIPART_UPLOAD_PART = 15000;

const isTerminalStatus = (status: ContextGenerationStatus): boolean => {
    return [ContextGenerationStatus.NONE, ContextGenerationStatus.SUCCEEDED, ContextGenerationStatus.FAILED].includes(status);
}

const escapeForCSV = (value: string) => {
    if (typeof value === 'string' ) {
        value = value.replace(/"/g, '""');
        return `"${value}"`;
    }
    return value;
}

export function ContextGenerationButton({shadowReportName}) {
    const { shadowReportMetadata } = useContext(ShadowReportMetadataContext);
    let realm = shadowReportMetadata[ShadowReportMetadataEntries.ShadowReportRegion];
    let isSupportedRealm = [ShadowReportRegions.NA, ShadowReportRegions.FE, ShadowReportRegions.EU].includes(realm);

    const [status, setStatus] = useState(ContextGenerationStatus.NONE);

    let description = "";
    let uiStatus: StatusIndicatorProps.Type = "in-progress";
    switch (status) {
        case ContextGenerationStatus.SUCCEEDED:
            uiStatus = "success";
            description = "File snapshot has been uploaded, please wait for Slack notification in #tip-enhanced-shadow-reports";
            break;
        case ContextGenerationStatus.NONE:
            break;
        case ContextGenerationStatus.UPLOADING:
            description = "Snapshotting file for context generation...";
            break;
        case ContextGenerationStatus.FAILED:
            uiStatus = "error";
            description = "File snapshot has failed."
            break;
    }

    const loadSheet = () => {
        return Excel.run(async (context) => {
            let sheet = context.workbook.worksheets.getFirst()

            let data = sheet.getUsedRange();
            data.load("values")
            data.load("rowCount");
            await context.sync();

            return data;
        });
    }

    const initiateUpload = async (data: Excel.Range) => {
        const rowCount = data.rowCount;
        const numParts = Math.ceil(rowCount / ROWS_PER_MULTIPART_UPLOAD_PART);
        const objectKey = `context/${realm}/${shadowReportName}`;
        const uploadId = await initiateMultipartUpload(objectKey);
        const presignedUrls = await getPresignedUrls(objectKey, uploadId, numParts);
        return {uploadId, objectKey, presignedUrls};
    }

    const upload = async (data: Excel.Range, presignedUrls: string[]) => {
        const uploadRequests: Promise<string>[] = [];
        const values = data.values;

        for (let i = 0; i < presignedUrls.length; i++) {
            let content = '';
            for (let j = 0; j < ROWS_PER_MULTIPART_UPLOAD_PART; j++) {
                const rowIndex = i * ROWS_PER_MULTIPART_UPLOAD_PART + j;
                if (rowIndex === data.rowCount) {
                    break;
                }
                const row = values[rowIndex].map(v => escapeForCSV(v));
                content += row.join(",") + "\r\n";
            }
            uploadRequests.push(uploadPart(content, presignedUrls[i]));
        }
        return await Promise.all(uploadRequests);
    }

    const uploadPart = async (content: string, preSignedUrl: string) => {
        const headers = new Headers({
            "Content-Type": "text/csv",
            'Access-Control-Expose-Headers': "ETag",
        });
        const response = await fetch(preSignedUrl, {
            method: "PUT",
            headers: headers,
            body: content,
        })
        return response.headers.get("ETag");
    }

    const generateContext = async () => {
        setStatus(ContextGenerationStatus.UPLOADING);
        let file;
        try {
            file = await loadSheet();

            const {uploadId, objectKey, presignedUrls} = await initiateUpload(file);

            const eTags = await upload(file, presignedUrls);
            await completeMultipartUpload([objectKey, uploadId, ...eTags])
            setStatus(ContextGenerationStatus.SUCCEEDED);
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : String(error);
            console.log(errorMessage);
            setStatus(ContextGenerationStatus.FAILED);
        }
    }

    return (
        <Container header={
            <Header>
                Context Generation
            </Header>
        }>
            <SpaceBetween direction="vertical" size={"s"}>
                {!isSupportedRealm && (
                    <Alert
                        type="warning"
                        header="Unsupported Realm"
                    >
                        Context generation is only supported for NA, EU and FE
                    </Alert>
                )}
                <Button variant="primary" iconName="upload"
                        disabled={!isSupportedRealm}
                        onClick={generateContext}>{isTerminalStatus(status) ? "Generate Context" : <Spinner/>} </Button>
                {
                    status !== ContextGenerationStatus.NONE &&
                    <StatusIndicator type={uiStatus}>
                        {description}
                    </StatusIndicator>
                }
            </SpaceBetween>
        </Container>
    );
}
