import { midwayFetch } from "../../../auth/fetcher";

const GET_PRE_SIGNED_URL_FOR_UPLOAD_ENDPOINT = "https://get-presigned-url-api.shadow-report-addin.tax.amazon.dev";

export const getPresignedURLForClusteringFileUploading = async (filename: string) => {
    const response =  await midwayFetch(`${GET_PRE_SIGNED_URL_FOR_UPLOAD_ENDPOINT}/get-presigned-url`, {
        method: "POST",
        body: JSON.stringify({
            objectKey: `clustering/${filename}`,
            method: "PUT",
        })
    })
    return await response.json();
}

const GET_CLUSTERING_RESULTS_ENDPOINT = "https://clustering-result.shadow-report-addin.tax.amazon.dev";

export const uploadCSVFileForClustring = async (clusteringResultURL: string, filePayload: string) => {
    const headers = new Headers({"Content-Type": "text/csv"});
    await fetch(clusteringResultURL, {
        method: "PUT",
        headers: headers,
        body: filePayload,
    })
}

export const getPresignedURLForClusteringResult = async (filename: string) => {
    const clusteringResultFileName = "clustering/" + filename.substring(0, filename.lastIndexOf(".")) + "_clustering.csv"
    const response =  await midwayFetch(`${GET_CLUSTERING_RESULTS_ENDPOINT}/object_key/${encodeURIComponent(clusteringResultFileName)}`);
    return await response.json();
}