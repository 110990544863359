import * as React from "react";
import {Container, Header} from "@amzn/awsui-components-react";
import {SnapshotButton} from "./TakeSnapshot/SnapshotButton";
import {ShadowReportType} from "../../../hook/UseShadowReportInfo";

export function Snapshot({shadowReportName, shadowReportType}) {
  if (shadowReportType === ShadowReportType.TAXMAN) {
    return (
      <Container header={
        <Header>
          Snapshot
        </Header>
      }>
        <SnapshotButton shadowReportName={shadowReportName} shadowReportType={shadowReportType}/>
      </Container>
    );
  } else {
    return null;
  }
}