export const EXCEL_FILE_START_COLUMN = "A";

export const convertExcelColumnToCSV = (column: string[][]) => {
    let result = column[0] + "\r\n";
    for (let i = 1; i < column.length; i++) {
        result += "\"" + column[i] + "\"" + "\r\n";
    }
    return result;
}

export const populateColumnHeader = (context: Excel.RequestContext, columnIndex: number, header: string) => {
    const sheet = loadSheet(context);
    sheet.getCell(0, columnIndex).values = [[`${header}`]]; 
}

export const insertEmptyCoumnsToTheLeftOfTheSheet = async (context: Excel.RequestContext, numberOfColumnToInsert: number) => {
    const sheet = loadSheet(context);

    const insertionEndColumn = buildColumnAddressFromColumnIndex(numberOfColumnToInsert - 1);
    let insertionColumnRange = sheet.getRange(`${EXCEL_FILE_START_COLUMN}:${insertionEndColumn}`);
    insertionColumnRange.insert(Excel.InsertShiftDirection.right);
    
    await context.sync();
}

export const loadSheet = (context: Excel.RequestContext) => {
    return context.workbook.worksheets.getFirst();
}

export const loadSheetUsedRange = async (context: Excel.RequestContext, sheet: Excel.Worksheet) => {
    const usedRange = sheet.getUsedRange();
    usedRange.load("rowCount");
    usedRange.load("columnCount");
    
    await context.sync();
    return usedRange;
}

export const loadHeader = async (context: Excel.RequestContext) => {        
    const sheet = await loadSheet(context); 
    const usedRange = await loadSheetUsedRange(context, sheet);

    const header = sheet.getRangeByIndexes(0, 0, 1, usedRange.columnCount);
    header.load("values");
    await context.sync();

    return (header.values as string[][])[0];
}

export const loadColumn = async (context: Excel.RequestContext, columnIndex: number) => {
    const sheet = await loadSheet(context); 
    const usedRange = await loadSheetUsedRange(context, sheet);

    let column = sheet.getRangeByIndexes(0, columnIndex, usedRange.rowCount, 1);
    column.load("values")
    await context.sync();

    return (column.values as string[][]);
}

export const buildColumnAddressFromColumnIndex = (offset: number) => {
    return String.fromCharCode(EXCEL_FILE_START_COLUMN.charCodeAt(0) + offset)
}