import {Metric} from "@amzn/tax-platform-console-metrics";
import {aliasStorageEntry} from "../storage/StorageEntries";
import {IShadowReportMetadata} from "../storage/ShadowReportMetadataSheet";

export const buildMetric = async (name: string, shadowReportMetadata?: IShadowReportMetadata): Promise<Metric> => {
    const metric = new Metric(name);
    metric.namespace = "TaxShadowReportAddIn";
    metric.addProperty("UserAlias", await aliasStorageEntry.get() || "unknown")
    if (shadowReportMetadata) {
        for (const key in shadowReportMetadata) {
            const value = shadowReportMetadata[key];
            const parsedValue = typeof value === "string" || typeof value === "number" ? value : JSON.stringify(value);
            if (value) {
                metric.addProperty(key, parsedValue);
            }
        }
    }
    return metric;
}