import {DiffStage, RuleFileTypeStatistic} from "./RuleFileTypeStatistic";


interface RuleFileVersions {
    stage: DiffStage,
    version: string;
}

export class RuleFileParser {
    private static readonly VERSION_EXTRACTION_REGEX = /VersionDiff\[(.*?) => (.*?)\]/;

    public extractRuleFileType(ruleFileStr: string): RuleFileTypeStatistic[] {
        const ruleFileAttr: string[] = ruleFileStr.split(':');

        if(!this.isValidCell(ruleFileStr)) {
            return [];
        }

        return this.extractVersions(ruleFileStr).map(version => ({
            stage: version.stage,
            service: ruleFileAttr[0],
            ruleFileType: ruleFileAttr[1],
            ruleStats: [{
                ruleFileLabel: ruleFileAttr[2],
                version: version.version,
                count: 1
            }]
        }))
    }

    public generateRuleFileKey(ruleFileStr: RuleFileTypeStatistic): string {
        return `${ruleFileStr.stage}:${ruleFileStr.service}:${ruleFileStr.ruleFileType}`
    }

    private extractVersions(ruleFileStr: string): [RuleFileVersions, RuleFileVersions] {
        const versions = ruleFileStr.match(RuleFileParser.VERSION_EXTRACTION_REGEX);

        if (versions && versions.length === 3) {
            return [{ stage: DiffStage.Authority, version: versions[1]}, { stage: DiffStage.Candidate, version: versions[2]}];
        }

        return [{ stage: DiffStage.Authority, version: "UNKNOWN"}, { stage: DiffStage.Candidate, version: "UNKNOWN"}];
    }

    private isValidCell(ruleFileStr: string): boolean {
        const versions = ruleFileStr.match(RuleFileParser.VERSION_EXTRACTION_REGEX);

        return versions && versions.length === 3;
    }
}
