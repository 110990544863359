export enum ShadowReportMetadataEntries {
    ShadowReportYear = "shadowReportYear",
    DeploymentType = "deploymentType",
    DeploymentId = "deploymentId",
    ShadowReportRegion = "shadowReportRegion",
    ShadowReportId = "shadowReportId",
    IsFinalized = "isFinalized",
}

export const ShadowReportMetadataFormFields = [ShadowReportMetadataEntries.ShadowReportYear,
                                               ShadowReportMetadataEntries.DeploymentType,
                                               ShadowReportMetadataEntries.DeploymentId,
                                               ShadowReportMetadataEntries.ShadowReportRegion,
                                               ShadowReportMetadataEntries.ShadowReportId];

export interface IShadowReportMetadata {
    [ShadowReportMetadataEntries.ShadowReportYear]: number,
    [ShadowReportMetadataEntries.DeploymentType]: DeploymentTypes,
    [ShadowReportMetadataEntries.DeploymentId]: string,
    [ShadowReportMetadataEntries.ShadowReportRegion]: ShadowReportRegions,
    [ShadowReportMetadataEntries.ShadowReportId]: string,
    [ShadowReportMetadataEntries.IsFinalized]: boolean,
}

export const SHADOW_REPORT_METADATA_SHEET_NAME = "ShadowReportMetadata";

export const SHADOW_REPORT_METADATA_UPDATED_EVENT_NAME = "shadowReportMetadataUpdated";

export enum DeploymentTypes {
    Vertex = "Vertex",
    RuleFile = "Rule File",
    Code = "Code",
    Weblab = "Weblab"
}

export enum ShadowReportRegions {
    NA = "NA",
    FE = "FE",
    EU = "EU",
    CN = "CN",
    AWS = "AWS",
    EUNA = "EUNA",
    BR = "BR",
}

export const INITIAL_METADATA: IShadowReportMetadata = {
    [ShadowReportMetadataEntries.ShadowReportYear]: undefined,
    [ShadowReportMetadataEntries.DeploymentType]: undefined,
    [ShadowReportMetadataEntries.DeploymentId]: undefined,
    [ShadowReportMetadataEntries.ShadowReportRegion]: undefined,
    [ShadowReportMetadataEntries.ShadowReportId]: undefined,
    [ShadowReportMetadataEntries.IsFinalized]: undefined,
}

export const getShadowReportMetadata = async (): Promise<IShadowReportMetadata> => {
    const shadowReportMetadata: IShadowReportMetadata = INITIAL_METADATA;
    await Excel.run(async (context) => {
        const shadowReportMetadataSheet = context.workbook.worksheets.getItemOrNullObject(SHADOW_REPORT_METADATA_SHEET_NAME);
        await context.sync();
        if (shadowReportMetadataSheet.isNullObject) {
            return null;
        }
        const headerRow = shadowReportMetadataSheet.getRange("A1").getEntireRow();
        headerRow.load("values");
        await context.sync();
        for (let entry of Object.values(ShadowReportMetadataEntries)) {
            const header = headerRow.findOrNullObject(entry, {completeMatch: true, matchCase: true});
            await context.sync();
            if (!header.isNullObject) {
                const valueCell = header.getRowsBelow();
                valueCell.load("values");
                await context.sync();
                // @ts-ignore
                shadowReportMetadata[entry] = valueCell.values[0][0];
            }
        }
    });
    return shadowReportMetadata;
}

export const setShadowReportMetadata = async (shadowReportMetadata: IShadowReportMetadata) => {
    await Excel.run(async (context) => {
        let shadowReportMetadataSheet = context.workbook.worksheets.getItemOrNullObject(SHADOW_REPORT_METADATA_SHEET_NAME);
        await context.sync();
        if (shadowReportMetadataSheet.isNullObject) {
            shadowReportMetadataSheet = context.workbook.worksheets.add(SHADOW_REPORT_METADATA_SHEET_NAME);
            await context.sync();
        }
        shadowReportMetadataSheet.getUsedRange().clear("All");
        let currentHeaderCell = shadowReportMetadataSheet.getRange('A1');
        for (let entry of Object.values(ShadowReportMetadataEntries)) {
            currentHeaderCell.values = [[entry]];
            const valueCell = currentHeaderCell.getRowsBelow();
            valueCell.values = [[shadowReportMetadata[entry]]];
            currentHeaderCell = currentHeaderCell.getColumnsAfter();
        }
        await context.sync();
    });
    document.dispatchEvent(new CustomEvent(SHADOW_REPORT_METADATA_UPDATED_EVENT_NAME, {detail: shadowReportMetadata}));
}