import {useEffect, useState} from "react";
import {ExcelClient} from "../../../excel/ExcelClient";
import {RULE_DIFFS_COL_NAME} from "../../../hook/UseShadowReportInfo";

export interface RuleDiffs {
  candidateRuleIds: string[],
  authoritativeRuleIds: string[]
}

export default function useRuleDiff() {
  const excelClient = ExcelClient.create();

  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [ruleDiffs, setRuleDiffs] = useState<RuleDiffs>(
    {
      candidateRuleIds: [],
      authoritativeRuleIds: []
    }
  );

  Excel.run(async (context) => {
    const firstWorksheet = context.workbook.worksheets.getFirst();
    await excelClient.registerOnSelectionChangedCallback(firstWorksheet, async () => {
      setActiveRowIndex(await excelClient.getActiveRowIndexOnMainSheet());
    })
    await excelClient.registerOnWorksheetActivatedCallback(firstWorksheet, async () => {
      setActiveRowIndex(await excelClient.getActiveRowIndexOnMainSheet());
    });
  });

  function parseRuleIdsFromRuleDiffsCell(ruleDiffsCellContent: string) {
    const empty = {
      authoritativeRuleIds: [],
      candidateRuleIds: []
    };

    if (ruleDiffsCellContent === "") {
      return empty;
    }

    const ruleFileLabelGroups = ruleDiffsCellContent.split(";,");
    return ruleFileLabelGroups
      .flatMap((group) => parseRuleIdsFromRuleFileLabelGroup(group))
      .reduce((prev, curr) => {
        prev.authoritativeRuleIds.push(...curr.authoritativeRuleIds)
        prev.candidateRuleIds.push(...curr.candidateRuleIds)
        return prev;
      }, empty)
  }

  function parseRuleIdsFromRuleFileLabelGroup(ruleFileLabelGroup: string) {
    const pattern = /(.*):(.*):RuleDiff\[\s*(.*) => (.*)\]/;
    const match = pattern.exec(ruleFileLabelGroup);

    const ruleFileType = match[2];
    const authoritativeRuleIdsLiteral = match[3];
    const candidateRuleIdsLiteral = match[4];

    return {
      candidateRuleIds: parseRuleIdsFromRuleIdLiteral(ruleFileType, candidateRuleIdsLiteral),
      authoritativeRuleIds: parseRuleIdsFromRuleIdLiteral(ruleFileType, authoritativeRuleIdsLiteral)
    };
  }

  function parseRuleIdsFromRuleIdLiteral(ruleFileType: string, ruleIdLiteral: string) {
    const openTrimmed = ruleIdLiteral.trim().replace(/(\d+x)*\[/g, ''); // Remove all [ and Nx[ for some int N
    const openClosedTrimmed = openTrimmed.trim().replace(/\]/g, ''); // Remove all ]
    return Array.from(new Set(
      openClosedTrimmed.split(",").filter(ruleId => ruleId !== "")
        .map(ruleId => `${ruleFileType}-${ruleId}`)
    ));
  }

  useEffect(() => {
    const loadRuleDiffs = async () => {
      const selectedRowIndex = activeRowIndex ?? await excelClient.getActiveRowIndexOnMainSheet();
      if (selectedRowIndex === null || selectedRowIndex < 1) {
        return;
      } else {
        const ruleDiffsColumn = await excelClient.getCellsDataByHeaderName(RULE_DIFFS_COL_NAME);
        const ruleDiffsCell = ruleDiffsColumn[selectedRowIndex - 1];
        const ruleDiffs = parseRuleIdsFromRuleDiffsCell(ruleDiffsCell);

        setRuleDiffs(ruleDiffs);
      }
    }
    loadRuleDiffs();
  }, [activeRowIndex])

  return ruleDiffs;
}

