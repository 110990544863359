import {ErrorHandlingStrategy, midwayFetch} from "../../../auth/fetcher";
import {getBackendHost} from "../../../config/shadow-report.api";

const FETCH_RESULT_API_ENDPOINT = `https://fetch-result.${getBackendHost()}`;

interface EnhancedReportData{
  url: string
}

interface EnhancedReportResponse {
  data: EnhancedReportData | null;
  error: {
    message: string;
    code: number;
  } | null;
}

export const fetchEnhancedReport = async (executionId: string) => {
  try {
    const response = await midwayFetch(
        `${FETCH_RESULT_API_ENDPOINT}/enhanced-report?executionId=${executionId}`,
        undefined,
        ErrorHandlingStrategy.DEFER_TO_CALLER
    );
    if (!response.ok) {
      return {
        data: null,
        error: {
          message: response.status === 404 ? 'Report not found' : 'Failed to fetch report',
          code: response.status
        }
      };
    }

    const data = await response.json();
    return { data, error: null };
  } catch (error) {
    return {
      data: null,
      error: {
        message: 'An unexpected error occurred',
        code: 500
      }
    };
  }
};

