import {useEffect, useState} from "react";
import {RuleContentReader} from "../../../excel/RuleContentReader";
import {RuleFile, Sheet} from "./RuleContent";

export function useRuleContent(ruleIds: string[]): RuleFile[] {
  const ruleContentReader = RuleContentReader.create();
  const [ruleContent, setRuleContent] = useState<RuleFile[]>([]);

  useEffect(() => {
    const read = async () => {
      setRuleContent(await ruleContentReader.readRuleFiles());
    }
    read();
  }, [])


  function findInterestedRuleContent(ruleIds: string[], ruleContent: RuleFile[]): RuleFile[] {
    const ret: RuleFile[] = []

    ruleIds.forEach((ruleId) => {
      const tokens = ruleId.split("-");
      const ruleFileId = tokens[0] + "-" + tokens[1] + "-" + tokens[2];
      const ruleSheetId = tokens[3];

      const filteredRuleFile = findRuleFile(ret, ruleFileId);
      if (filteredRuleFile) {
        const filteredRuleSheet = findRuleSheet(filteredRuleFile, ruleSheetId);

        if (filteredRuleSheet) {
          const ruleFile = findRuleFile(ruleContent, ruleFileId);
          const ruleSheet = findRuleSheet(ruleFile, ruleSheetId);
          const rule = findRule(ruleSheet, ruleId);

          filteredRuleSheet.rules.push(rule);
        } else {
          const ruleFile = findRuleFile(ruleContent, ruleFileId);

          const ruleSheet = findRuleSheet(ruleFile, ruleSheetId);
          const clonedRuleSheet: Sheet = {
            ...ruleSheet,
            rules: []
          }

          const rule = findRule(ruleSheet, ruleId);
          filteredRuleFile.sheets.push(clonedRuleSheet);
          clonedRuleSheet.rules.push(rule);
        }
      } else {
        const ruleFile = findRuleFile(ruleContent, ruleFileId);
        const clonedRuleFile: RuleFile = {
          ...ruleFile,
          sheets: []
        }

        const ruleSheet = findRuleSheet(ruleFile, ruleSheetId);
        const clonedRuleSheet: Sheet = {
          ...ruleSheet,
          rules: []
        }

        const rule = findRule(ruleSheet, ruleId);
        clonedRuleFile.sheets.push(clonedRuleSheet);
        clonedRuleSheet.rules.push(rule);
        ret.push(clonedRuleFile)
      }
    })

    return ret;
  }

  function findRuleFile(ruleContent: RuleFile[], ruleFileId: string) {
    return ruleContent.find((ruleFile) => ruleFile.id === ruleFileId ||
      // TODO: This is a temp fix to handle `Global-` / `<Realm>-` prefix and other delimiters
      (ruleFile.id && ruleFile.id.split('-').slice(1).join('-').replace(/[^0-9a-z]/gi, '') === ruleFileId.replace(/[^0-9a-z]/gi, ''))
    )
  }

  function findRuleSheet(ruleFile: RuleFile, sheetId: string) {
    if (!ruleFile) {
      return null;
    }
    return ruleFile.sheets.find((sheet) => sheet.id === sheetId);
  }

  function findRule(ruleSheet: Sheet, ruleId: string) {
    if (!ruleSheet) {
      return null;
    }
    return ruleSheet.rules.find((rule) => rule.id === ruleId ||
      // TODO: This is a temp fix to handle `Global-` / `<Realm>-` prefix and other delimiters
      (rule.id && rule.id.split('-').slice(1).join('-').replace(/[^0-9a-z]/gi, '') === ruleId.replace(/[^0-9a-z]/gi, ''))
    )
  }

  return findInterestedRuleContent(ruleIds, ruleContent);
}