import {Button, Checkbox, CheckboxProps, Container, Header, SpaceBetween, StatusIndicator, StatusIndicatorProps } from "@amzn/awsui-components-react";
import {ClusteringType, ShadowReportType} from "../../../hook/UseShadowReportInfo";
import React, {useEffect, useState} from "react";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react/polaris/internal/events";
import useClusteringButton, { ClusteringJobsState } from "./useClusteringButton";

const SUPPORTED_SHADOW_REPORT_TYPES = [ShadowReportType.TRE, ShadowReportType.TAXMAN, ShadowReportType.TRE_V2];

interface ClusteringStateConfiguration {
    isTerminalState: boolean,
    stateDescription?: string,
    statusIndicatorType?: StatusIndicatorProps.Type
}

const ClusteringStateConfigurations: { [key in ClusteringJobsState]: ClusteringStateConfiguration } = {
    [ClusteringJobsState.NEVER_TRIGGERED]: {
        isTerminalState: true,
    },
    [ClusteringJobsState.UPLOADING]: {
        isTerminalState: false,
        stateDescription: "Uploading Shadow Report for clustering...",
        statusIndicatorType: "in-progress"
    },
    [ClusteringJobsState.CLUSTERING]: {
        isTerminalState: false,
        stateDescription: "Clustering Shadow Report...",
        statusIndicatorType: "in-progress",
    },
    [ClusteringJobsState.POPULATING_EXCEL]: {
        isTerminalState: false,
        stateDescription: "Assigning clusters to Shadow Report...",
        statusIndicatorType: "in-progress",
    },
    [ClusteringJobsState.SUCCEEDED]: {
        isTerminalState: true,
        stateDescription: "Clustering has finished successfully",
        statusIndicatorType: "success",
    },
    [ClusteringJobsState.FAILED]: {
        isTerminalState: true,
        stateDescription: "Clustering has failed",
        statusIndicatorType: "error",
    },
    [ClusteringJobsState.TIMED_OUT]: {
        isTerminalState: true,
        stateDescription: "Clustering has timed-out",
        statusIndicatorType: "warning",
    }
}

interface ClusteringButtonProps {
    shadowReportName: string,
    shadowReportType: ShadowReportType,
    availableClusteringTypes: Set<ClusteringType>
}

export const ClusteringButton = (props: ClusteringButtonProps) => {
    const [selectedClusteringTypes, setSelectedClusteringTypes] = useState<Array<ClusteringType>>(Array.from(props.availableClusteringTypes));
    const { handleRunClustering, clusteringJobsState, processedClusteringTypes  } = useClusteringButton({
        shadowReportName: props.shadowReportName,
        shadowReportType: props.shadowReportType,
        selectedClusteringTypes: selectedClusteringTypes
    })

    const isSupportedShadowReportType = SUPPORTED_SHADOW_REPORT_TYPES.includes(props.shadowReportType);
    const shouldDisableButton = !isSupportedShadowReportType || selectedClusteringTypes.length === 0;
    const clusteringStateConfiguration = ClusteringStateConfigurations[clusteringJobsState];
    const isLoading = !clusteringStateConfiguration.isTerminalState;
    const buttonText = isSupportedShadowReportType ? "Trigger Clustering" : "Unsupported Shadow Report Type";

    useEffect(() => {
        setSelectedClusteringTypes(prevSelectedClusterTypes => prevSelectedClusterTypes.filter(type => !processedClusteringTypes.includes(type)));
    }, [processedClusteringTypes]);

    useEffect(() => {
        setSelectedClusteringTypes(Array.from(props.availableClusteringTypes));
    }, [props.availableClusteringTypes]);

    const handleCheckClusteringType = (clusteringType: ClusteringType) => {
        return (event: NonCancelableCustomEvent<CheckboxProps.ChangeDetail>) => {
            if (event.detail.checked) {
                setSelectedClusteringTypes([...selectedClusteringTypes, clusteringType]);
            } else {
                setSelectedClusteringTypes(selectedClusteringTypes.filter((type) => type !== clusteringType));
            }
        }
    }

    return (
        <Container header={
            <Header>
                2. Clustering
            </Header>
        }>
            <SpaceBetween direction="vertical" size={"s"}>
                {Array.from(props.availableClusteringTypes.values()).map(clusteringType => {
                    return <Checkbox
                        key={clusteringType}
                        onChange={handleCheckClusteringType(clusteringType)}
                        checked={selectedClusteringTypes.includes(clusteringType)}
                        disabled={processedClusteringTypes.includes(clusteringType)}
                    >
                        {clusteringType}
                    </Checkbox>
                })}
                <Button variant="primary" iconName="upload"
                    disabled={shouldDisableButton}
                    loading={isLoading}
                    onClick={handleRunClustering}>{buttonText}
                </Button>
                {
                    clusteringStateConfiguration.stateDescription &&
                    <StatusIndicator type={clusteringStateConfiguration.statusIndicatorType}>
                        {clusteringStateConfiguration.stateDescription}
                    </StatusIndicator>
                }
            </SpaceBetween>
        </Container>
    );
}
