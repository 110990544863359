import React from "react";
import {useTaxContentPanel} from "./useTaxContentPanel";
import {useQuery} from "@tanstack/react-query";
import {RuleFileTypeItem} from "./RuleFileTypeItem";
import {ClusterStatistic} from "./ClusterStatistic";


export function TaxContextPanel() {
    const { extractRuleFileCounts, getRuleFileCount, getRuleFileTypeHeader } = useTaxContentPanel();

    const {data: ruleFileTypes, isLoading} = useQuery({
        queryKey: ['extractRuleFileCounts'], queryFn: () => extractRuleFileCounts(),
    });

    if (isLoading && !ruleFileTypes) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <ClusterStatistic/>
            <h2 style={{marginLeft: 10}}>RuleFile diffs</h2>
            {!isLoading && ruleFileTypes && ruleFileTypes
                .sort((a, b) => getRuleFileCount(b) - getRuleFileCount(a))
                .map(ruleFileType =>
                <RuleFileTypeItem key={getRuleFileTypeHeader(ruleFileType)} ruleFileCount={getRuleFileCount(ruleFileType)} ruleFileStatistic={ruleFileType}/>
            )}
        </div>
    )
}
