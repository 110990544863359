import App from "./components/App";
import { AppContainer } from "react-hot-loader";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { configureProductMetricsLib } from "@amzn/tax-platform-console-metrics";
import { Stage as MetricsStage } from "@amzn/tax-platform-console-metrics";
import {ShadowReportMetadataProvider} from "../contexts/ShadowReportMetadataContext";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

/* global document, Office, module, require */

let isOfficeInitialized = false;
const queryClient = new QueryClient()

const title = "Tax Shadow Report Add-in";

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <QueryClientProvider client={queryClient}>
      <ShadowReportMetadataProvider>
        <Component title={title} isOfficeInitialized={isOfficeInitialized} />
      </ShadowReportMetadataProvider>
      </QueryClientProvider>
    </AppContainer>,
    document.getElementById("container")
  );
};

/* Render application after Office initializes */
Office.onReady(() => {
  isOfficeInitialized = true;
  configureProductMetricsLib("TaxShadowReportAddIn", MetricsStage.ALPHA);
  render(App);
});

if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    render(NextApp);
  });
}
