import * as React from "react";
import {useContext, useState} from "react";
import {Button} from "@amzn/awsui-components-react/polaris";
import {
    closeFile,
    completeMultipartUpload,
    getFile,
    getFormattedCurrentTimestamp,
    getPresignedUrls,
    getSlice,
    initiateMultipartUpload,
    uploadPart
} from "../Utils";
import {validateAndPublishMetric} from "@amzn/tax-platform-console-metrics";
import {buildMetric} from "../../../../metric/metric";
import {aliasStorageEntry} from "../../../../storage/StorageEntries";
import {Checkbox, ProgressBar, SpaceBetween, StatusIndicatorProps} from "@amzn/awsui-components-react";
import {ShadowReportMetadataContext} from "../../../../contexts/ShadowReportMetadataContext";
import {setShadowReportMetadata, ShadowReportMetadataEntries} from "../../../../storage/ShadowReportMetadataSheet";

const FILE_EXTENSION = "xlsx";
const SLICES_PER_PART = 2;

enum TaskLabel {
    NOTHING = "",
    EXTRACT = "Extracting file...",
    Initiate = "Initiating upload...",
    UPLOAD = "Uploading to S3...",
    FINALIZE = "Finalizing upload...",
    SUCCESS = "Success",
    ERROR = "Error",
}

const IN_PROGRESS_LABELS = [TaskLabel.EXTRACT, TaskLabel.Initiate, TaskLabel.UPLOAD, TaskLabel.FINALIZE];

export function SnapshotButton({shadowReportName, shadowReportType}) {
    const {shadowReportMetadata} = useContext(ShadowReportMetadataContext);

    const [taskLabel, setTaskLabel] = useState<TaskLabel>(TaskLabel.NOTHING);
    const [percentageCompleted, setPercentageCompleted] = useState<number>(0);
    const [finalChecked, setFinalChecked] = useState<boolean>(false);
    const [overrideFinalChecked, setOverrideFinalChecked] = useState<boolean>(false);

    const generateObjectKey = async () => {
        let isFinalizedKeyword = "";
        if (finalChecked) {
            isFinalizedKeyword = "-final";
        } else if (overrideFinalChecked) {
            isFinalizedKeyword = "-final-override";
        }
        const {
            shadowReportYear,
            shadowReportRegion,
            shadowReportId,
            deploymentId,
            deploymentType
        } = shadowReportMetadata;
        const userAlias = await aliasStorageEntry.get() || "unknown"
        return `${shadowReportType}/${shadowReportYear}/${deploymentType}/${deploymentId}/${shadowReportRegion}/${shadowReportId}/${shadowReportId}-${getFormattedCurrentTimestamp()}-${userAlias}${isFinalizedKeyword}.${FILE_EXTENSION}`;
    }

    const completeUpload = async (objectKey: string, uploadId: string, eTags: string[]) => {
        setTaskLabel(TaskLabel.FINALIZE);
        await completeMultipartUpload(objectKey, uploadId, eTags)
    }

    const upload = async (file: Office.File, presignedUrls: string[]) => {
        setTaskLabel(TaskLabel.UPLOAD);
        const sliceCount = file.sliceCount;
        const numParts = Math.ceil(sliceCount / SLICES_PER_PART);

        const uploadRequests: Promise<string>[] = [];
        for (let i = 0; i < numParts; i++) {
            let fileContent = [];
            for (let j = 0; j < SLICES_PER_PART; j++) {
                const sliceIndex = i * SLICES_PER_PART + j;
                if (sliceIndex === sliceCount) {
                    break;
                }
                const sliceData: any = await getSlice(file, sliceIndex);
                sliceData.forEach(function (v) {
                    fileContent.push(v);
                });
            }
            uploadRequests.push(uploadPart(presignedUrls[i], new Uint8Array(fileContent)));
            setPercentageCompleted(((i + 1) / numParts) * 49 + 50);
        }
        return await Promise.all(uploadRequests);
    }

    const initiateUpload = async (file: Office.File) => {
        setTaskLabel(TaskLabel.Initiate);
        const sliceCount = file.sliceCount;
        const numParts = Math.ceil(sliceCount / SLICES_PER_PART);
        const objectKey = await generateObjectKey();
        const uploadId = await initiateMultipartUpload(objectKey);
        const presignedUrls = await getPresignedUrls(objectKey, uploadId, numParts);
        return {uploadId, objectKey, presignedUrls};
    }

    const extractFile = async () => {
        setTaskLabel(TaskLabel.EXTRACT);
        return await getFile();
    }

    const takeSnapshot = async () => {
        const metric = await buildMetric("Snapshotting", shadowReportMetadata);
        metric.addProperty("FileName", shadowReportName);
        let file;
        try {
            file = await extractFile()
            setPercentageCompleted(25);
            metric.addProperty("FileSize", file.size);

            const {uploadId, objectKey, presignedUrls} = await initiateUpload(file);
            setPercentageCompleted(50);

            const eTags = await upload(file, presignedUrls);
            await completeUpload(objectKey, uploadId, eTags);
            setPercentageCompleted(100);
            await setShadowReportMetadata({
                ...shadowReportMetadata,
                isFinalized: finalChecked || overrideFinalChecked,
            });
            metric.addProperty("UploadType", finalChecked ? "Final" : overrideFinalChecked ? "Final Override" : "Non-final");
            setTaskLabel(TaskLabel.SUCCESS);
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : String(error);
            setTaskLabel(TaskLabel.ERROR);
            metric.addProperty("ErrorMessage", errorMessage.substring(0, 256));
        } finally {
            setFinalChecked(false);
            setOverrideFinalChecked(false);
            setPercentageCompleted(0);
            validateAndPublishMetric(metric);
            if (file) {
                await closeFile(file);
            }
        }
    }

    let statusType: StatusIndicatorProps.Type = "in-progress";
    switch (taskLabel) {
        case TaskLabel.SUCCESS:
            statusType = "success";
            break;
        case TaskLabel.ERROR:
            statusType = "error";
            break;
        default:
    }

    return (
        <SpaceBetween direction="vertical" size={"s"}>
            <Checkbox checked={finalChecked}
                      onChange={({detail}) => setFinalChecked(detail.checked)}
                      disabled={shadowReportMetadata[ShadowReportMetadataEntries.IsFinalized]}>Final</Checkbox>
            <Checkbox checked={overrideFinalChecked}
                      onChange={({detail}) => setOverrideFinalChecked(detail.checked)}
                      disabled={shadowReportMetadata && !shadowReportMetadata[ShadowReportMetadataEntries.IsFinalized]}>Override
                Final</Checkbox>
            <Button onClick={takeSnapshot} loading={IN_PROGRESS_LABELS.includes(taskLabel)}
                    disabled={!(shadowReportMetadata && shadowReportName && shadowReportType) || (shadowReportMetadata[ShadowReportMetadataEntries.IsFinalized] && !overrideFinalChecked)}
                    iconName="upload"
                    variant="primary">
                Snapshot
            </Button>
            {taskLabel !== TaskLabel.NOTHING &&
                <ProgressBar label={IN_PROGRESS_LABELS.includes(taskLabel) ? taskLabel : ""} status={statusType}
                             value={percentageCompleted} resultText={taskLabel}/>}
        </SpaceBetween>
    );
}
