import {useContext, useEffect, useRef, useState} from "react";
import {
    CLUSTERING_TYPES,
    ClusteringResultType,
    ClusteringType,
    DIFF_SUMMARY_COL_NAME,
    DIFF_SUMMARY_COL_NAME_WITH_SPACE,
    MISMATCHING_FIELDS_SHEET_COL_NAME,
    RULE_DIFFS_COL_NAME,
    RULE_FILE_DIFFS_COL_NAME,
    ShadowReportType
} from "../../../hook/UseShadowReportInfo";
import {
    convertExcelColumnToCSV,
    insertEmptyColumnsToTheLeftOfTheSheet,
    loadColumns,
    loadHeader,
    loadSheet,
    loadSheetUsedRange,
    populateColumnHeader
} from "./file.helper";
import {v4 as uuidv4} from "uuid";
import {
    getPresignedURLForClusteringFileUploading,
    getPresignedURLForClusteringResult,
    uploadCSVFileForClustring
} from "./api.helper";
import {validateAndPublishMetric} from "@amzn/tax-platform-console-metrics";
import {buildMetric} from "../../../metric/metric";
import Papa from "papaparse";
import {IShadowReportMetadata} from "../../../storage/ShadowReportMetadataSheet";
import {ShadowReportMetadataContext} from "../../../contexts/ShadowReportMetadataContext";
import {ExcelClient} from "../../../excel/ExcelClient";


const CLUSTERING_QUERY_INTERVAL_MS = 5_000;

const CLUSTERING_TIMEOUT_MS = 900_000; // 15 minutes

export enum ClusteringJobsState {
    NEVER_TRIGGERED,
    UPLOADING,
    CLUSTERING,
    POPULATING_EXCEL,
    TIMED_OUT,
    FAILED,
    SUCCEEDED
}

interface ClusteringButtonProps {
    shadowReportName: string,
    shadowReportType: ShadowReportType,
    selectedClusteringTypes: Array<ClusteringType>
}

interface ClusteringJob {
    clusteringResultPreSignedURL: string,
    clusteringJobType: ClusteringType,
    timeoutTimestamp: number,
    clusteringResult?: string
}

const excelClient: ExcelClient = ExcelClient.create();

const getClusteringResultTypes = (clusteringType: ClusteringType) => {
    if (clusteringType === ClusteringType.DIFF_SUMMARY) {
        return [ClusteringResultType.DIFF_SUMMARY_CLUSTER];
    } else if (clusteringType === ClusteringType.RULE_DIFFS) {
        return [ClusteringResultType.RULE_FILE_DIFFS_CLUSTER, ClusteringResultType.RULE_DIFFS_CLUSTER];
    }
    return [];
}

const generatedClusterTopicsColumnHeader = (clusteringResultType: ClusteringResultType) => {
    return `${clusteringResultType} Topics`;
}

const generatedClusterColumnHeader = (clusteringResultType: ClusteringResultType) => {
    return `${clusteringResultType} Cluster`;
}

const getColumnNamesToRunClusteringOn = (clusteringType: ClusteringType, shadowReportType: ShadowReportType) => {
    switch (clusteringType) {
        case ClusteringType.RULE_DIFFS:
            return [RULE_FILE_DIFFS_COL_NAME, RULE_DIFFS_COL_NAME]
        case ClusteringType.DIFF_SUMMARY:
            return getColumnNameToRunClusteringOnForDiffSummaryClusteringType(shadowReportType)
    }
}

const getColumnNameToRunClusteringOnForDiffSummaryClusteringType = (shadowReportType: ShadowReportType) => {
    switch (shadowReportType) {
        case ShadowReportType.TAXMAN:
            return [MISMATCHING_FIELDS_SHEET_COL_NAME];
        case ShadowReportType.TRE:
            return [DIFF_SUMMARY_COL_NAME];
        case ShadowReportType.TRE_V2:
            return [DIFF_SUMMARY_COL_NAME_WITH_SPACE];
        case ShadowReportType.UNKNOWN:
            throw new Error("Unsupported shadow report type");
    }
}

const insertEmptyColumnsForClusteringResults = async (selectedClusteringTypes: Array<ClusteringType>) => {
    const numberEmptyOfColumns = selectedClusteringTypes.flatMap(selectedClusteringType => getClusteringResultTypes(selectedClusteringType)).length;

    await Excel.run(async context => {
        await insertEmptyColumnsToTheLeftOfTheSheet(context, numberEmptyOfColumns * 2);
    })
}

const populateHeadersForClusteringResultColumns = async (selectedClusteringTypes: Array<ClusteringType>) => {
    let columnIdx = 0;
    await Excel.run(async context => {
        selectedClusteringTypes.forEach(selectedClusteringTypes => {
            let clusteringResultTypes = getClusteringResultTypes(selectedClusteringTypes);
            clusteringResultTypes.forEach(clusteringResultType => {
                populateColumnHeader(context, columnIdx * 2, generatedClusterColumnHeader(clusteringResultType));
                populateColumnHeader(context, columnIdx * 2 + 1, generatedClusterTopicsColumnHeader(clusteringResultType));
                columnIdx++;
            });
        });
    });
}

function getColumnIndexes(header: string[], columnNames: string[]) {
    return columnNames.map(columnName => header.indexOf(columnName))
}

const loadColumnToRunClusteringOn = async (clusteringType: ClusteringType, shadowReportType: ShadowReportType) => {
    return Excel.run(async context => {
        const columnNamesToRunClusteringOn = getColumnNamesToRunClusteringOn(clusteringType, shadowReportType);
        const header = await loadHeader(context);

        const columnIndexesToRunClusteringOn = getColumnIndexes(header, columnNamesToRunClusteringOn);
        const columns = await loadColumns(context, columnIndexesToRunClusteringOn);

        if (ShadowReportType.TRE_V2 === shadowReportType && ClusteringType.DIFF_SUMMARY === clusteringType) {
            columns[0][0] = DIFF_SUMMARY_COL_NAME;
        }

        return { clusteringType, columns };
    })
}

const uploadColumnsForClustering = async (selectedClusteringTypes: Array<ClusteringType>, shadowReportType: ShadowReportType) => {
    const clusteringTypesWithColumns = await Promise.all(selectedClusteringTypes.map(type => loadColumnToRunClusteringOn(type, shadowReportType)));

    return await Promise.all(clusteringTypesWithColumns.map(async clusteringTypeWithColumns => {
        const filename = `${clusteringTypeWithColumns.clusteringType.replace(/ /g , '').toLowerCase()}/${uuidv4()}.csv`;

        // To test populating clustering results, comment out the following 2 lines.
        let preSignedURL = await getPresignedURLForClusteringFileUploading(filename);
        await uploadCSVFileForClustring(preSignedURL, convertExcelColumnToCSV(clusteringTypeWithColumns.columns));

        return filename;
    }))
}

const generateClusteringResultFilePreSignedURLs = async (clusteringInputFilenames: string[]) => {
    // To test populating clustering results, return an array of URL to download the mock clustering results here.
    return await Promise.all(clusteringInputFilenames.map(filename => getPresignedURLForClusteringResult(filename)))
}

const generateClusteringJobsToQuery = (clusteringTypes: Array<ClusteringType>, clusteringResultFilePreSignedURLs: string[]) => {
    return clusteringTypes.map((clusteringType, idx) => {
        return {
            clusteringResultPreSignedURL: clusteringResultFilePreSignedURLs[idx],
            clusteringJobType: clusteringType,
            timeoutTimestamp: CLUSTERING_TIMEOUT_MS + Date.now(),
        }
    });
}

const queryClusteringResult = async (job: ClusteringJob) => {
    const resp = await fetch(job.clusteringResultPreSignedURL);
    if (resp.ok) {
        job.clusteringResult = await resp.text();
    } else if (resp.status !== 404) {
        Promise.reject(resp);
    }
    return job;
}

const haveAllClusteringJobFinishedSuccessfully = (clusteringJobs: Array<ClusteringJob>) => {
    return clusteringJobs.every(job => job.clusteringResult);
}

const doesAnyClusteringJobTimeout = (clusteringJobs: Array<ClusteringJob>, shadowReportMetadata: IShadowReportMetadata) => {
    return clusteringJobs.find(job => {
        const timedOut = job.timeoutTimestamp < Date.now();
        if (timedOut) {
            buildAndPublishClusteringTimeoutMetric(shadowReportMetadata, job.clusteringResultPreSignedURL);
            return true;
        }
        return false;
    });
}

const parseClusteringResult = (clusteringResult: string) => {
    const config = {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        delimiter: ","
    }
    return Papa.parse(clusteringResult, config);
}

const populateColumnsWithClusteringJobResult = async (shadowReportMetadata: IShadowReportMetadata, succeededJob: ClusteringJob) => {
    return await Excel.run(async (context) => {
        const parsedClusteringResult = parseClusteringResult(succeededJob.clusteringResult);
        const header = await loadHeader(context);
        const resultTypes = getClusteringResultTypes(succeededJob.clusteringJobType);
        const sheet = loadSheet(context);
        for (let i = 0; i < resultTypes.length; i++) {
            const resultType = resultTypes[i];
            const clusterHeader = generatedClusterColumnHeader(resultType);
            const clusterTopicsHeader = generatedClusterTopicsColumnHeader(resultType);

            const columnIndexes = getColumnIndexes(header, [clusterHeader, clusterTopicsHeader]);
            for (let j = 0; j < columnIndexes.length; j++) {
                const columnIndex = columnIndexes[j];
                const columnName = header[columnIndex];
                const populatedColumn = parsedClusteringResult.data.map((row) => [row[columnName]]);
                const range = sheet.getRangeByIndexes(1, columnIndex, populatedColumn.length, 1)
                range.values = populatedColumn;
            }
        }
        await context.sync();

        buildAndPublishClusteringResultMetric(shadowReportMetadata, countUniqueNumberOfSuggestedClusters(parsedClusteringResult))
    })
}

const countUniqueNumberOfSuggestedClusters = (clusteringResult) => {
    const set = new Set();
    clusteringResult.data.forEach((row) => set.add(row['SuggestedCluster']));
    return set.size
}

const buildAndPublishClusteringTimeoutMetric = async(shadowReportMetadata: IShadowReportMetadata, clusteringResultFilePreSignedURL: string) => {
    const metric = await buildMetric("ClusteringTimeout", shadowReportMetadata);

    metric.addCount("ClusteringTimeout", 1);
    metric.addProperty("Url", clusteringResultFilePreSignedURL);
    validateAndPublishMetric(metric);
}

const buildAndPublishClusteringTriggeredMetric = async(
    shadowReportMetadata: IShadowReportMetadata,
    shadowReportName: string,
    shadowReportType: string,
    clusteringTypes: Array<ClusteringType>
) => {
    const metric = await buildMetric("Clustering", shadowReportMetadata);

    metric.addProperty("ShadowReportName", shadowReportName);
    metric.addProperty("ShadowReportType", shadowReportType);
    metric.addProperty("ClusteringTypes", clusteringTypes.join(","));
    metric.addCount("ClusteringInvoked", 1);

    Excel.run(async context => {
        const sheet = loadSheet(context);
        const usedRange = await loadSheetUsedRange(context, sheet);

        metric.addProperty("rows", usedRange.rowCount)
    })

    validateAndPublishMetric(metric);
}

const buildAndPublishClusteringResultMetric = async(
    shadowReportMetadata: IShadowReportMetadata,
    numberOfSuggestedClusters: number
) => {
    const metric = await buildMetric("ClusteringResult", shadowReportMetadata);

    metric.addProperty("NumClusters", numberOfSuggestedClusters);
    validateAndPublishMetric(metric);
}

const createEmptyClusteringResultsColumns = async (clusteringTypes: Array<ClusteringType>) => {
    await insertEmptyColumnsForClusteringResults(clusteringTypes);
    await populateHeadersForClusteringResultColumns(clusteringTypes);
}

const useClusteringButton = (props: ClusteringButtonProps) => {
    const {shadowReportMetadata} = useContext(ShadowReportMetadataContext);
    const [clusteringJobsState, setClusteringJobsState] = useState<ClusteringJobsState>(ClusteringJobsState.NEVER_TRIGGERED);
    const [pendingClusteringJobs, setPendingClusteringJobs] = useState<ClusteringJob[]>([]);
    const [completedClusteringJobs, setCompletedClusteringJobs] = useState<ClusteringJob[]>([]);
    const [processedClusteringTypes, setProcessedClusteringTypes] = useState<ClusteringType[]>([])

    const queryClusteringJobsStatusRef = useRef(setTimeout(() => {
    }));
    const [timestamp, setTimestamp] = useState<number>(Date.now());

    const clusteringTypeOrder = Object.values(ClusteringType);
    const clusteringTypes = Array.from(props.selectedClusteringTypes)
        .sort((a, b) => clusteringTypeOrder.indexOf(a) - clusteringTypeOrder.indexOf(b));


    const cleanPendingClusteringJobs = () => {
        clearTimeout(queryClusteringJobsStatusRef.current);
        setPendingClusteringJobs([]);
    }

    const handleClusteringJobFailure = (e) => {
        console.log(e);
        cleanPendingClusteringJobs();
        setClusteringJobsState(ClusteringJobsState.FAILED);
    }

    const enterUploadingState = async () => {
        setClusteringJobsState(ClusteringJobsState.UPLOADING);
        return await uploadColumnsForClustering(clusteringTypes, props.shadowReportType)
    }

    const enterPopulateExcelState = async(succeededJobs: Array<ClusteringJob>) => {
        setClusteringJobsState(ClusteringJobsState.POPULATING_EXCEL);

        succeededJobs.forEach(succeededJob => {
            populateColumnsWithClusteringJobResult(shadowReportMetadata, succeededJob);
        })
    }

    const sortByRuleFileDiffsClusterColumn = async () => {
        const ruleFileDiffsHeader = generatedClusterColumnHeader(ClusteringResultType.RULE_FILE_DIFFS_CLUSTER);
        const ruleDiffsHeader = generatedClusterColumnHeader(ClusteringResultType.RULE_DIFFS_CLUSTER);
        const headers = await excelClient.getHeaders();
        if(headers.includes(ruleFileDiffsHeader) && headers.includes(ruleDiffsHeader)) {
            await excelClient.sortByColumnHeaders([ruleFileDiffsHeader, ruleDiffsHeader], true);
        }
    }

    const enterClusteringState = async (clusteringResultFilenames: string[]) => {
        setClusteringJobsState(ClusteringJobsState.CLUSTERING);
        const clusteringResultFilePreSignedURLs = await generateClusteringResultFilePreSignedURLs(clusteringResultFilenames);
        setPendingClusteringJobs(generateClusteringJobsToQuery(clusteringTypes, clusteringResultFilePreSignedURLs));
    }

    const handleRunClustering = async () => {
        try {
            await createEmptyClusteringResultsColumns(clusteringTypes);
            await refreshProcessedClusteringType()
            const clusteringResultFilenames = await enterUploadingState();

            await enterClusteringState(clusteringResultFilenames);
        } catch (e) {
            handleClusteringJobFailure(e);
        } finally {
            await buildAndPublishClusteringTriggeredMetric(shadowReportMetadata, props.shadowReportName, props.shadowReportType, props.selectedClusteringTypes);
        }
    }

    const handleClusteringJobsTimeout = async () => {
        setClusteringJobsState(ClusteringJobsState.TIMED_OUT);
        cleanPendingClusteringJobs();
    }

    const handleClusteringJobsSuccess = (succeededJobs: Array<ClusteringJob>) => {
        try {
            enterPopulateExcelState(succeededJobs)
                .then(sortByRuleFileDiffsClusterColumn)
            setClusteringJobsState(ClusteringJobsState.SUCCEEDED)
        } catch (e) {
            handleClusteringJobFailure(e);
        }
    }

    const refreshProcessedClusteringType = async (): Promise<void> => {
        const headers = await Excel.run(async context => {
            return await loadHeader(context)
        });

        setProcessedClusteringTypes(CLUSTERING_TYPES.filter(clusteringType => {
            return headers.includes(`${clusteringType} Topics`);
        }))
    }

    useEffect(() => {
        refreshProcessedClusteringType();
    }, []);

    useEffect(() => {
        if (pendingClusteringJobs.length > 0) {
            queryClusteringJobsStatusRef.current = setTimeout(async () => {
                if (doesAnyClusteringJobTimeout(pendingClusteringJobs, shadowReportMetadata)) {
                    handleClusteringJobsTimeout();
                } else {
                    await Promise.all(pendingClusteringJobs.map(job => queryClusteringResult(job)))
                        .then(() => {
                            if (haveAllClusteringJobFinishedSuccessfully(pendingClusteringJobs)) {
                                setCompletedClusteringJobs(pendingClusteringJobs);
                                cleanPendingClusteringJobs();
                            }
                            // retry as clustering jobs are still running
                        }).catch((e) => {
                            handleClusteringJobFailure(e);
                        }).finally(() => {
                            setTimestamp(Date.now())
                        })
                }
            }, CLUSTERING_QUERY_INTERVAL_MS);
        }

        return () => {
            clearTimeout(queryClusteringJobsStatusRef.current);
        }
    }, [timestamp, pendingClusteringJobs])

    useEffect(() => {
        if (completedClusteringJobs.length > 0) {
            handleClusteringJobsSuccess(completedClusteringJobs);
        }
    }, [completedClusteringJobs])

    return { handleRunClustering, clusteringJobsState, processedClusteringTypes }
}

export default useClusteringButton;
