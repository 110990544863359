import React from "react";
import useRuleDiff from "./useRuleDiffs"
import {useRuleContent} from "./useRuleContent";
import {ColumnLayout, Container, Header, Table, Tabs} from "@amzn/awsui-components-react";
import {RuleFile, Sheet} from "./RuleContent";

export function RuleContentPanel() {
  const ruleDiff = useRuleDiff();
  const authoritativeRuleContent = useRuleContent(ruleDiff.authoritativeRuleIds);
  const candidateRuleContent = useRuleContent(ruleDiff.candidateRuleIds);

  function getRuleFileContent(ruleFiles: RuleFile[]) {
    return ruleFiles.map(ruleFile => {
      return getRuleFileTabs(ruleFile);
    })
  }

  function getRuleFileTabs(ruleFile: RuleFile) {
    const tableTabs = ruleFile.sheets.map(sheet => {
      return getRuleFileSheetTableTab(ruleFile, sheet);
    }).filter(x => x !== null)

    return (
      <Tabs tabs={tableTabs}/>
    )
  }

  function getRuleFileSheetTableTab(ruleFile: RuleFile, ruleSheet: Sheet) {
    if (!ruleSheet.header) {
      return null;
    }
    const columnDefinitions = ruleSheet.header.map((column, idx) => {
      return {
        id: column,
        header: column,
        cell: item => {
          if (!item) {
            return null;
          } else {
            return item.columns[idx]
          }
        }
      }
    })

    const rowHeader = {
      id: "row",
      header: "#",
      cell: item => {
        if (!item) {
          return null;
        } else {
          const itemIdSplit = item.id.split('-')
          return itemIdSplit[itemIdSplit.length - 1]
        }
      }
    }

    columnDefinitions.unshift(rowHeader)

    const table = (
      <Table items={ruleSheet.rules} columnDefinitions={columnDefinitions} header={
        <Header variant="h2">
          {ruleFile.id}
        </Header>
      }
      />
    )

    return {
      label: ruleSheet.id,
      id: ruleSheet.id,
      content: table
    }
  }

  return (
    <ColumnLayout columns={2}>
      <Container
        header={
          <Header
            variant="h2"
          >
            Authoritative
          </Header>
        }
      >
        {getRuleFileContent(authoritativeRuleContent)}
      </Container>
      <Container
        header={
          <Header
            variant="h2"
          >
            Candidate
          </Header>
        }
      >
        {getRuleFileContent(candidateRuleContent)}
      </Container>
    </ColumnLayout>
  )
}