import * as React from "react";
import {Container, Header} from "@amzn/awsui-components-react";
import {SnapshotButton} from "./TakeSnapshot/SnapshotButton";

export function Snapshot({shadowReportName, shadowReportType}) {
    return (
        <Container header={
            <Header>
                Snapshot
            </Header>
        }>
            <SnapshotButton shadowReportName={shadowReportName} shadowReportType={shadowReportType}/>
        </Container>
    );
}