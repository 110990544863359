import Button from "@amzn/awsui-components-react/polaris/button";
import {Box, ExpandableSection} from "@amzn/awsui-components-react";
import React from "react";
import {RuleFileTypeStatistic, RuleTypeCount} from "../../../taxcontext/RuleFileTypeStatistic";
import {ClickDetail} from "@amzn/awsui-components-react/polaris/internal/events";
import {useTaxContentPanel} from "./useTaxContentPanel";

interface RuleFileTypeItemProps {
    ruleFileStatistic: RuleFileTypeStatistic
    ruleFileCount: RuleTypeCount;
}

export function RuleFileTypeItem({ ruleFileStatistic, ruleFileCount}: RuleFileTypeItemProps) {
    const { filterRowByRuleFileType, getRuleFileTypeHeader } = useTaxContentPanel();

    async function requestToFilterByRuleFile(event: CustomEvent<ClickDetail>): Promise<void> {
        event.stopPropagation();
        await filterRowByRuleFileType(ruleFileStatistic);
    }

    return <ExpandableSection headerText={
        <div style={{display: 'flex', justifyContent: 'space-between', width: '90vw'}}>
            <Box>
                <p style={{margin: 0, alignSelf: 'flex-start'}}>{`${getRuleFileTypeHeader(ruleFileStatistic)} - ${ruleFileCount}`}</p>
            </Box>
            <Box float='right'>
                <Button onClick={e => requestToFilterByRuleFile(e)} variant="primary">
                    Filter
                </Button>
            </Box>
        </div>}>
        {ruleFileStatistic.ruleStats.map((ruleTypeStatistic) =>
            <p>{`${ruleTypeStatistic.ruleFileLabel}:${ruleTypeStatistic.ruleFileLabel}:${ruleTypeStatistic.version} - `}<b>{ruleTypeStatistic.count}</b></p>)}
    </ExpandableSection>
}
