export interface RuleFileTypeStatistic {
    stage: DiffStage;
    service: string;
    ruleFileType: string;
    ruleStats: RuleTypeStatistic[];
}

export interface RuleTypeStatistic {
    version?: string;
    ruleFileLabel?: string;
    count: number;
}

export interface Cluster {
    clusterName: string;
    count: number;
}

export enum DiffStage {
    Authority = "Authority",
    Candidate = "Candidate",
}

export type RuleTypeCount = number;

