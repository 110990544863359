export const enUS = {
    inContextInputPlaceholder: "s3://bucket/prefix/",
    inContextSelectPlaceholder: "Choose a version",
    inContextBrowseButton: "Browse S3",
    inContextViewButton: "View",
    inContextLoadingText: "Loading resource",
    inContextUriLabel: "",
    inContextVersionSelectLabel: "Object version",
    modalTitle: "Choose an archive in S3",
    modalCancelButton: "Cancel",
    modalSubmitButton: "Choose",
    modalBreadcrumbRootItem: "S3 buckets",
    selectionBuckets: "Buckets",
    selectionObjects: "Objects",
    selectionVersions: "Versions",
    selectionBucketsSearchPlaceholder: "Find bucket",
    selectionObjectsSearchPlaceholder: "Find object by prefix",
    selectionVersionsSearchPlaceholder: "Find version",
    selectionBucketsLoading: "Loading buckets",
    selectionBucketsNoItems: "No buckets",
    selectionObjectsLoading: "Loading objects",
    selectionObjectsNoItems: "No objects",
    selectionVersionsLoading: "Loading versions",
    selectionVersionsNoItems: "No versions",
    filteringCounterText: (count) => "" + count + (count === 1 ? " match" : " matches"),
    filteringNoMatches: "No matches",
    filteringCantFindMatch: "We can't find a match.",
    clearFilterButtonText: "Clear filter",
    // columnBucketID: "ID",
    columnBucketName: "Name",
    columnBucketCreationDate: "Creation date",
    columnBucketRegion: "Region",
    // columnBucketAccess: "Access",
    // columnObjectID: "ID",
    columnObjectKey: "Key",
    columnObjectLastModified: "Last modified",
    columnObjectSize: "Size",
    columnVersionID: "Version ID",
    // columnVersionCreationDate: "Creation date",
    columnVersionLastModified: "Last modified",
    columnVersionSize: "Size",
    validationPathMustBegin: "The path must begin with s3://",
    validationBucketLowerCase: "The bucket name must start with a lowercase character or number.",
    validationBucketMustNotContain: "The bucket name must not contain uppercase characters.",
    validationBucketMustComplyDns: "The bucket name must comply with DNS naming conventions",
    validationBucketLength: "The bucket name must be from 3 to 63 characters.",
    labelSortedDescending: (columnName) => columnName + ", sorted descending",
    labelSortedAscending: (columnName) => columnName + ", sorted ascending",
    labelNotSorted: (columnName) => columnName + ", not sorted",
    labelsPagination: {
        nextPageLabel: "Next page",
        previousPageLabel: "Previous page",
        pageLabel: (pageNumber) => "Page " + pageNumber + " of all pages"
    },
    labelsBucketsSelection: {
        // @ts-ignore
        itemSelectionLabel: (data, item) => item.Name ?? "",
        selectionGroupLabel: "Buckets"
    },
    labelsObjectsSelection: {
        // @ts-ignore
        itemSelectionLabel: (data, item) => item.Key ?? "",
        selectionGroupLabel: "Objects"
    },
    labelsVersionsSelection: {
        // @ts-ignore
        itemSelectionLabel: (data, item) => item.VersionId ?? "",
        selectionGroupLabel: "Versions"
    },
    labelFiltering: (itemsType) => "Find " + itemsType,
    labelRefresh: "Refresh the data",
    // labelAlertDismiss: "Dismiss the alert",
    labelModalDismiss: "Dismiss the modal",
    labelBreadcrumbs: "S3 navigation"
}

export const STAGE_TO_BUCKET_MAP = {
    alpha: "tax-shadow-report-snapshots-bucket-alpha",
    beta: "tax-shadow-report-snapshots-bucket-beta",
    prod: "tax-shadow-report-snapshots-bucket-prod"
};

export function getS3BucketName() {
    const hostname = window.location.hostname;

    if (hostname.includes("alpha")) {
        return STAGE_TO_BUCKET_MAP.alpha;
    }
    if (hostname.includes("beta")) {
        return STAGE_TO_BUCKET_MAP.beta;
    }
    if (hostname.includes("prod")) {
        return STAGE_TO_BUCKET_MAP.prod;
    }

    return STAGE_TO_BUCKET_MAP.alpha;;
}

export const S3_BUCKET_NAME = getS3BucketName();
export const S3_URI_PREFIX = `s3://${S3_BUCKET_NAME}/`;
