import {Cluster} from "../../../taxcontext/RuleFileTypeStatistic";
import {ExcelClient} from "../../../excel/ExcelClient";
import {RuleFileTypeStatisticUtil} from "../../../taxcontext/RuleFileTypeStatisticUtil";

export function useClusterStatistic() {
    const excelClient = ExcelClient.create();
    const statisticUtil = new RuleFileTypeStatisticUtil();

    async function getClusterStatistics(): Promise<Cluster[]> {
        const headers = await excelClient.getHeaders();
        const existingClusters = statisticUtil.getExistingClusters(headers);

        return Promise.all(existingClusters.map(async existingCluster => ({
            clusterName: existingCluster,
            count: getClusterCount(await excelClient.getCellsDataByHeaderName(existingCluster))
        })));
    }

    function getClusterCount(cells: string[]): number {
        const clusters = new Set();

        cells.forEach(cell => {
            if ("" + cell) {
                clusters.add("" + cell);
            }
        });

        return clusters.size;
    }

    return { getClusterStatistics }
}
