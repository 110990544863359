import {TaxContextAnalyzer} from "../../../taxcontext/TaxContextAnalyzer";
import {RuleFileTypeStatistic, RuleTypeStatistic} from "../../../taxcontext/RuleFileTypeStatistic";
import {ExcelClient} from "../../../excel/ExcelClient";
import {RULE_FILE_DIFFS_COL_NAME} from "../../../hook/UseShadowReportInfo";
import {useQuery} from "@tanstack/react-query";
import {RuleFileParser} from "../../../taxcontext/RuleFileParser";

export function useTaxContentPanel() {
    const taxContextAnalyzer = TaxContextAnalyzer.create();
    const excelClient = ExcelClient.create();
    const ruleFileParser = new RuleFileParser();

    const { data: hasTaxContextColumn } = useQuery({
        queryKey: ['hasTaxContext'],
        queryFn: hasTaxContext,
        initialData: () => false,
        refetchInterval: 1000,
    });

    async function extractRuleFileCounts(): Promise<RuleFileTypeStatistic[]> {
        const ruleFileDiffs = await extractRuleFileCellData(RULE_FILE_DIFFS_COL_NAME);

        return taxContextAnalyzer.extractRuleFileTypes(ruleFileDiffs);
    }

    function getRuleFileCount(ruleFileStatistic: RuleFileTypeStatistic): number {
        return ruleFileStatistic.ruleStats.reduce((acc: number, ruleTypes: RuleTypeStatistic) => acc + ruleTypes.count, 0);
    }

    async function extractRuleFileCellData(headerName: string): Promise<string[]> {
        const cellsData = await excelClient.getCellsDataByHeaderName(headerName);

        return cellsData.filter(cell => cell.length > 0);
    }

    async function filterRowByRuleFileType(ruleFileStatic: RuleFileTypeStatistic): Promise<void> {
        await excelClient.filterColumnByString(RULE_FILE_DIFFS_COL_NAME, `${ruleFileStatic.service}:${ruleFileStatic.ruleFileType}`)
    }

    function getRuleFileTypeHeader(ruleFileType: RuleFileTypeStatistic): string {
        return ruleFileParser.generateRuleFileKey(ruleFileType)
    }

    async function hasTaxContext(): Promise<boolean> {
        const headers = await excelClient.getHeaders();

        return taxContextAnalyzer.containsTaxContext(headers);
    }

    return {
        extractRuleFileCounts,
        filterRowByRuleFileType,
        getRuleFileCount,
        getRuleFileTypeHeader,
        hasTaxContextColumn
    };
}
