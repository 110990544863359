import React, {useEffect, useState} from "react";

export const useShadowReportName = () => {
    const [shadowReportFilename, setShadowReportFilename] = useState("");

    const getFileNameFromPath = (path: string) => {
        return path.replace(/^.*[\\\/]/, '');
    }

    useEffect(() => {
        const getType = async () => {
            await Excel.run(async () => {
                Office.context.document.getFilePropertiesAsync((result) => {
                    if (result.status === Office.AsyncResultStatus.Succeeded) {
                        const fileName = getFileNameFromPath(result.value.url);
                        console.log(`Filename: ${fileName}`);
                        setShadowReportFilename(fileName);
                    } else {
                        const err = result.error;
                        console.log(err.name + ": " + err.message);
                    }
                })
            })
        }
        getType()
    }, [])

    return shadowReportFilename;
}