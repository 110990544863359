import ExcelJS from "exceljs"
import {
    findColumnIndices,
    getWorkSheetByName,
    getHeaderRow,
    getUsedRange,
    HEADER_NAMES,
    CONTEXT_SHEET_NAME
} from "./utils";

const CHUNK_SIZE = 5000;

export interface ContextData {
    ruleFileDiffs: string;
    ruleDiffs: string;
}

export interface ContextMap {
    [key: string]: ContextData;
}

export interface ProcessingResult {
    success: boolean;
    error?: Error;
}

export function buildContextMap(contextSheet: ExcelJS.Worksheet): ContextMap {
    if (!contextSheet) {
        throw new Error('Context sheet is required');
    }

    const contextMap: ContextMap = {};

    try {
        // Get the header row (assuming it's the first row)
        const headerRow = contextSheet.getRow(1);

        // Find column indices dynamically
        const columnIndices = findColumnIndices(headerRow);

        contextSheet.eachRow((row, rowNum) => {
            if (rowNum === 1) return; // Skip header row

            const getCellValue = (colIndex: number): string => {
                const cell = row.getCell(colIndex);
                return cell?.text?.trim() ?? '';
            };

            const tarIdentifier = getCellValue(columnIndices.tarIdentifier);
            const trIdentifier = getCellValue(columnIndices.trIdentifier);

            if (tarIdentifier === '' || trIdentifier === '') return;

            const contextData: ContextData = {
                ruleFileDiffs: getCellValue(columnIndices.ruleFileDiffs),
                ruleDiffs: getCellValue(columnIndices.ruleDiffs)
            };

            const key = `${tarIdentifier}||${trIdentifier}`;
            contextMap[key] = contextData;
        });

        return contextMap;
    } catch (error) {
        throw new Error(`Failed to build context map: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
}

export async function mergeContextDataIntoWorkbook(contextMap: ContextMap): Promise<ProcessingResult> {
    if (!contextMap) {
        return {success: false, error: new Error('No context data provided')};
    }

    try {
        await Excel.run(async (context) => {
            const contextSheet = await getWorkSheetByName(context, CONTEXT_SHEET_NAME);
            await insertRuleFileDiffsAndRuleDiffsColumns(contextSheet, context);

            const usedRange = await getUsedRange(contextSheet, context);
            const headers = await getHeaderRow(contextSheet, usedRange.columnCount, context);
            const {tarColumnIndex, trColumnIndex} = findKeyIndexes(headers);

            await processDataInChunks(contextSheet, usedRange.rowCount, contextMap,tarColumnIndex, trColumnIndex, context);
        });

        return {success: true};
    } catch (error) {
        console.error('Error during merge operation:', error);
        return {success: false, error: error as Error};
    }
}

function findKeyIndexes(headers: string[]): { tarColumnIndex: number; trColumnIndex: number } {
    const findColumnIndex = (columnName: string) =>
        headers.findIndex(h => h?.toString().trim().toLowerCase() === columnName.toLowerCase());

    const tarIndex = findColumnIndex(HEADER_NAMES.tarIdentifier);
    const trIndex = findColumnIndex(HEADER_NAMES.trIdentifier);

    if (tarIndex < 0 || trIndex < 0) {
        throw new Error(`Could not find ${HEADER_NAMES.tarIdentifier} or ${HEADER_NAMES.trIdentifier} columns.`);
    }

    return {tarColumnIndex: tarIndex, trColumnIndex: trIndex};
}

async function insertRuleFileDiffsAndRuleDiffsColumns(sheet: Excel.Worksheet, context: Excel.RequestContext): Promise<void> {
    sheet.getRange("A:B").insert(Excel.InsertShiftDirection.right);
    const headerRange = sheet.getRange("A1:B1");
    headerRange.values = [[HEADER_NAMES.ruleFileDiffs, HEADER_NAMES.ruleDiffs]]
    await context.sync();
}

async function processDataInChunks(
    sheet: Excel.Worksheet,
    totalRows: number,
    contextMap: ContextMap,
    tarColumnIndex: number,
    trColumnIndex: number,
    context: Excel.RequestContext
): Promise<void> {
    for (let startRow = 1; startRow <= totalRows; startRow += CHUNK_SIZE) {
        const rowCount = Math.min(CHUNK_SIZE, totalRows - startRow + 1)
        await processChunk(sheet, startRow, rowCount, tarColumnIndex, trColumnIndex, contextMap, context);
    }
}

async function processChunk(
    sheet: Excel.Worksheet,
    startRow: number,
    rowCount: number,
    tarColumnIndex: number,
    trColumnIndex: number,
    contextMap: ContextMap,
    context: Excel.RequestContext
): Promise<void> {
    const tarColumnRange = sheet.getRangeByIndexes(startRow, tarColumnIndex, rowCount, 1);
    const trColumnRange = sheet.getRangeByIndexes(startRow, trColumnIndex, rowCount, 1);

    tarColumnRange.load('values');
    trColumnRange.load('values');
    await context.sync();

    const diffs: string[][] = Array(rowCount).fill(null)
        .map(() => ['', '']);

    let matchCount = 0;
    for (let i = 0; i < tarColumnRange.values.length; i++) {
        const tarVal = (tarColumnRange.values[i][0]?.toString() || '').trim();
        const trVal = (trColumnRange.values[i][0]?.toString() || '').trim();

        if (!tarVal || !trVal || tarVal === '' || trVal === '') continue;

        const key = `${tarVal}||${trVal}`;
        const contextData = contextMap[key];

        if (contextData) {
            diffs[i][0] = contextData.ruleFileDiffs;
            diffs[i][1] = contextData.ruleDiffs;
            matchCount++;
        }
    }

    console.log(`Found ${matchCount} matches in current chunk`);

    // Column A - Rule File Diffs
    // Column B - Rule Diffs
    const startColumn = 0
    const columnCount = 2
    const DiffsDataRange = sheet.getRangeByIndexes(startRow, startColumn, rowCount, columnCount)
    DiffsDataRange.values = diffs;

    await context.sync();
}

