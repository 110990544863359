import * as React from "react";
import {useContext, useState} from "react";
import {Alert, Button, Container, Header, Link, SpaceBetween} from "@amzn/awsui-components-react";
import {Snapshot} from "../SnapshotButton/Snapshot";
import {ContextGenerationButton} from "../ContextGenerationButton/ContextGenerationButton";
import {ClusteringButton} from "../ClusteringButton/ClusteringButton";
import {ShadowReportType, useShadowReportInfo} from "../../../hook/UseShadowReportInfo";
import {
  ShadowReportMetadataContext,
  ShadowReportMetadataEditingContext
} from "../../../contexts/ShadowReportMetadataContext";
import {EnhancedReportFetcher} from "../EnhancedReportFetcher/EnhancedReportFetcher";
import {ShadowReportMetadataForm} from "../ShadowReportMetadataForm/ShadowReportMetadataForm";

const FEEDBACK_LINK = "https://survey.fieldsense.whs.amazon.dev/survey/5da69cb6-28b7-4417-a4cb-fd0f5a1042a7";

interface LandingPageProps {
  shadowReportName: string
}

export function LandingPage({shadowReportName}: LandingPageProps) {
  const [reportChangeTrigger, setReportChangeTrigger] = useState(0);

  // Notify Report columns info change after successfully fetching the enhanced report
  const notifyReportChange = () => {
    setReportChangeTrigger(prev => prev + 1);
  };

  const {
    shadowReportType,
    availableClusteringTypes,
    ruleFileAndRuleDiffsExist
  } = useShadowReportInfo(reportChangeTrigger);
  const {shadowReportMetadataFormFieldsAssigned} = useContext(ShadowReportMetadataContext);
  const {shadowReportMetadataEditing, setShadowReportMetadataEditing} = useContext(ShadowReportMetadataEditingContext);

  const headerText = ShadowReportType.UNKNOWN === shadowReportType ?
    "Shadow Report Type Unknown" :
    `${shadowReportType} Shadow Report`;

  const metadataNeeded = shadowReportType === ShadowReportType.TAXMAN;

  return (
    <Container header={
      <>
        <Header>
          {headerText}
        </Header>
        <Alert statusIconAriaLabel="info">
          VPN connection is required.
        </Alert>
      </>
    }
               footer={
                 <Link href={FEEDBACK_LINK}>Feedback</Link>
               }
    >
      {metadataNeeded && (shadowReportMetadataEditing || !shadowReportMetadataFormFieldsAssigned) ?
        <ShadowReportMetadataForm shadowReportName={shadowReportName}/> :
        <SpaceBetween direction="vertical" size={"s"}>
          <EnhancedReportFetcher shadowReportName={shadowReportName} contextDiffsExit={ruleFileAndRuleDiffsExist}
                                 onReportChange={notifyReportChange}/>
          <ContextGenerationButton shadowReportName={shadowReportName}/>
          <ClusteringButton shadowReportName={shadowReportName} shadowReportType={shadowReportType}
                            availableClusteringTypes={availableClusteringTypes}/>
          <Snapshot shadowReportName={shadowReportName} shadowReportType={shadowReportType}/>
          {metadataNeeded &&
              <Button onClick={() => setShadowReportMetadataEditing(true)}
                      iconName="edit">
                  Edit Metadata
              </Button>
          }
        </SpaceBetween>
      }
    </Container>
  );
}
