import {useClusterStatistic} from "./useClusterStatistic";
import {Table} from "@amzn/awsui-components-react";
import {useQuery} from "@tanstack/react-query";
import React from "react";

export function ClusterStatistic() {
    const { getClusterStatistics } = useClusterStatistic();

    const {data: clusterData, isLoading} = useQuery({
        queryKey: ['getClusterStatistics'], queryFn: getClusterStatistics,
    });

    if (isLoading || clusterData.length === 0) {
        return <div></div>;
    }

    return <div style={{padding: 15, paddingTop: 0}}><Table columnDefinitions={[
        {
            id: 'clusterName',
            header: "Cluster Name",
            cell: item => item.clusterName
        }, {
            id: 'count',
            header: "Count",
            cell: item => item.count
        }
    ]} items={clusterData}/></div>;
}
