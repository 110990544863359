type StorageItem = {
    type: string;
    value: string | any[];
}

type StorageItemMapEntry = {
    objectKey: string;
    objectValue: StorageItem;
}

export interface PlatformStorage {
    getItem(key: string): string | null;
    setItem(key: string, value: string): void;
}

export class Storage {
    private platformStorage: PlatformStorage;

    constructor(platformStorage) {
        this.platformStorage = platformStorage;
    }

    async set(key: string, value: any): Promise<void> {
        const storageItem = Storage.convertToStorageItem(value);
        return this.platformStorage.setItem(key, JSON.stringify(storageItem));
    }

    async get(key: string): Promise<any> {
        const value = JSON.parse(this.platformStorage.getItem(key));
        if (value === null) {
            return null;
        }

        // Discard incompatible values in old format. We don't know what type is expected.
        if (!value.type) {
            return null;
        }

        const convertedStorageItem = Storage.convertFromStorageItem(value as StorageItem);
        return convertedStorageItem === null ? null : convertedStorageItem;
    }

    static convertToStorageItem(value: any): StorageItem {
        let storageItemValue;
        let storageItemType;
        if (value instanceof Set) {
            storageItemValue = [];
            storageItemType = "Set";
            value.forEach(itemInSet => storageItemValue.push(this.convertToStorageItem(itemInSet)));
        } else if (value instanceof Array) {
            storageItemValue = [];
            storageItemType = "Array";
            value.forEach(itemInArray => storageItemValue.push(this.convertToStorageItem(itemInArray)));
        } else if (value instanceof Map) {
            storageItemValue = [];
            storageItemType = "ES6Map";
            value.forEach((mapValue, mapKey) => {
                storageItemValue.push(this.convertToStorageItem({ key: mapKey, value: mapValue }));
            });
        } else if (typeof value === "boolean") {
            storageItemType = "Boolean";
            storageItemValue = value.toString();
        } else if (typeof value === "number") {
            storageItemType = "Number";
            storageItemValue = value.toString();
        } else if (typeof value === "string") {
            storageItemType = "String";
            storageItemValue = value;
        } else if (value instanceof Object) {
            storageItemType = "Map";
            storageItemValue = [];
            for (const [objectKey, objectValue] of Object.entries(value)) {
                storageItemValue.push({ objectKey, objectValue: this.convertToStorageItem(objectValue) });
            }
        }
        return {
            value: storageItemValue,
            type: storageItemType,
        };
    }

    static convertFromStorageItem(storageItem: StorageItem): any {
        const { value, type } = storageItem;

        if (type === "Set" && value instanceof Array) {
            const setValues = value.map(itemInSet => this.convertFromStorageItem(itemInSet));
            return new Set(setValues);
        }
        if (type === "Array" && value instanceof Array) {
            return value.map(itemInArray => this.convertFromStorageItem(itemInArray));
        }
        if (type === "ES6Map" && value instanceof Array) {
            const mapValues = value.map(itemInMap => this.convertFromStorageItem(itemInMap));
            const map = new Map();
            mapValues.forEach((mapValue) => {
                map.set(mapValue.key, mapValue.value);
            });
            return map;
        }
        if (type === "Boolean") {
            return value === "true";
        }
        if (type === "Number") {
            return Number(value);
        }
        if (type === "String") {
            return value;
        }
        if (type === "Map" && value instanceof Array) {
            return value.reduce((result, mapEntry: StorageItemMapEntry) => {
                result[mapEntry.objectKey] = this.convertFromStorageItem(mapEntry.objectValue);
                return result;
            }, {});
        }
        return null;
    }
}
const storage = new Storage(localStorage);

export default storage;