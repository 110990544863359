import {midwayFetch} from "../../../auth/fetcher";
import {getBackendHost} from "../../../config/shadow-report.api";

const GET_PRESIGNED_URL_ENDPOINT = `https://get-presigned-url-api.${getBackendHost()}`;
const CONTENT_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export function getSlice(file: Office.File, sliceIndex: number) {
  return new Promise((resolve, reject) => {
    file.getSliceAsync(sliceIndex, (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value.data);
      } else {
        reject(result.error);
      }
    })
  });
}

export function getFile(): Promise<Office.File> {
  return new Promise((resolve, reject) => {
    Office.context.document.getFileAsync(Office.FileType.Compressed, (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value);
      } else {
        reject(result.error);
      }
    })
  });
}

export function closeFile(file: Office.File) {
  return new Promise<void>((resolve, reject) => {
    file.closeAsync((result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve();
      } else {
        reject(result.error);
      }
    })
  });
}

export async function initiateMultipartUpload(objectKey: string) {
  return await midwayFetch(`${GET_PRESIGNED_URL_ENDPOINT}/start-upload`, {
    method: "POST",
    body: JSON.stringify({
      objectKey: objectKey
    }),
  })
    .then(resp => resp.json());
}

export async function getPresignedUrls(objectKey: string, uploadId: string, numParts: number) {
  return await midwayFetch(`${GET_PRESIGNED_URL_ENDPOINT}/get-presigned-urls`, {
    method: "POST",
    body: JSON.stringify({
      objectKey: objectKey,
      uploadId: uploadId,
      numParts: numParts,
    }),
  })
    .then(resp => resp.json());
}

export async function uploadPart(presignedUrl: string, data: Uint8Array) {
  const headers = new Headers({
    'Content-Type': CONTENT_TYPE,
    'Access-Control-Expose-Headers': "ETag",
  });
  const response = await fetch(presignedUrl, {
    method: "PUT",
    headers: headers,
    body: data,
  })
  return response.headers.get("ETag");
}

export async function completeMultipartUpload(eTags: string[]) {
  await midwayFetch(`${GET_PRESIGNED_URL_ENDPOINT}/complete-upload`, {
    method: "POST",
    body: JSON.stringify(eTags),
  });
}

export function getFormattedCurrentTimestamp() {
  const date = new Date(Date.now());
  const dateIsoString = date.toISOString();
  // removing milliseconds
  return dateIsoString.substring(0, dateIsoString.indexOf('.')) + "Z";
}

// @ts-ignore
export function fetchS3ResourceSelectorObjects(bucketName: string, pathPrefix: string) {
  return midwayFetch(`${GET_PRESIGNED_URL_ENDPOINT}/list-objects-with-prefix`, {
    method: "POST",
    body: JSON.stringify({
      pathPrefix
    }),
  })
    .then(resp => resp.json());
}

export function getPresignedUrl(objectKey: string) {
  return midwayFetch(`${GET_PRESIGNED_URL_ENDPOINT}/get-presigned-url`, {
    method: "POST",
    body: JSON.stringify({
      objectKey,
      method: "GET",
    })
  })
    .then(resp => resp.json());
}