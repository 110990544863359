import {useEffect, useState} from "react";

export const MISMATCHING_FIELDS_SHEET_COL_NAME = "mismatchingFields";
export const DIFF_SUMMARY_COL_NAME = "DiffSummary";
export const DIFF_SUMMARY_COL_NAME_WITH_SPACE = "Diff Summary";
export const TAR_IDENTIFIER_COL_NAME = "TAR Identifier";
export const RULE_FILE_DIFFS_COL_NAME = "Rule File Diffs"
export const RULE_DIFFS_COL_NAME = "Rule Diffs"

export enum ShadowReportType {
    TAXMAN = "TAXMAN",
    TRE = "TRE",
    TRE_V2 = "TRE (V2)",
    UNKNOWN = "Unknown",
}

export enum ClusteringType {
    DIFF_SUMMARY = "Diff Summary",
    RULE_DIFFS = "Rule Diffs"
}

export enum ClusteringResultType {
    DIFF_SUMMARY_CLUSTER = "Diff Summary",
    RULE_FILE_DIFFS_CLUSTER = "Rule File Diffs",
    RULE_DIFFS_CLUSTER = "Rule Diffs",
}

export const CLUSTERING_TYPES: ClusteringType[] = Object.values(ClusteringType);

interface ShadowReportInfo {
    shadowReportType: ShadowReportType;
    availableClusteringTypes: Set<ClusteringType>;
}

export const useShadowReportInfo = (): ShadowReportInfo => {
    const [shadowReportType, setShadowReportType] = useState<ShadowReportType>(ShadowReportType.UNKNOWN);
    const [availableClusteringTypes, setAvailableClusteringTypes] = useState(new Set<ClusteringType>([ClusteringType.DIFF_SUMMARY]));

    useEffect(() => {
        const determineShadowReportType = async () => {
            await Excel.run(async (context) => {
                let sheet = context.workbook.worksheets.getFirst()
                await context.sync()

                let usedRange = sheet.getUsedRange();
                usedRange.load("columnCount");
                await context.sync();

                const headerRow = sheet.getRangeByIndexes(0, 0, 1, usedRange.columnCount);
                headerRow.load("values");
                await context.sync()

                const headerValues = headerRow.values;
                if (headerValues[0].indexOf(MISMATCHING_FIELDS_SHEET_COL_NAME) !== -1) {
                    setShadowReportType(ShadowReportType.TAXMAN);
                } else if (headerValues[0].indexOf(DIFF_SUMMARY_COL_NAME) !== -1) {
                    setShadowReportType(ShadowReportType.TRE);
                } else if (headerValues[0].indexOf(TAR_IDENTIFIER_COL_NAME) !== -1) {
                    setShadowReportType(ShadowReportType.TRE_V2);
                } else {
                    setShadowReportType(ShadowReportType.UNKNOWN);
                }

                let clusteringTypes = new Set<ClusteringType>([ClusteringType.DIFF_SUMMARY])
                if (headerValues[0].indexOf(RULE_FILE_DIFFS_COL_NAME) !== -1 && headerValues[0].indexOf(RULE_DIFFS_COL_NAME) !== -1) {
                    clusteringTypes.add(ClusteringType.RULE_DIFFS);
                }
                setAvailableClusteringTypes(clusteringTypes)
            })
        };
        determineShadowReportType()
            .catch(error => {
                const errorMessage = error instanceof Error ? error.message : String(error);
                console.log("Shadow Report Type could not be determined: " + errorMessage);
            });
    }, []);

    return {
        shadowReportType: shadowReportType,
        availableClusteringTypes: availableClusteringTypes
    };
}
