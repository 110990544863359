import { Storage } from "./Storage";

export default class StorageEntry<T> {
    private readonly key: string;
    private readonly storage: Storage;

    private cachedValue: T | undefined = undefined;
    // A separate flag is needed because `null` and `undefined` value also have to be cached.
    private hasCachedValue = false;

    constructor(key: string, storage: Storage) {
        this.key = key;
        this.storage = storage;
    }

    async get(): Promise<T> {
        if (this.hasCachedValue) {
            return this.cachedValue;
        }
        this.cachedValue = await this.storage.get(this.key);
        this.hasCachedValue = true;
        return this.cachedValue;
    }

    async set(value: T): Promise<void> {
        this.cachedValue = value;
        this.hasCachedValue = true;
        await this.storage.set(this.key, value);
    }

    async isSet(): Promise<boolean> {
        const value = await this.get();
        return value !== null && value !== undefined;
    }
}