import { RuleFile, Sheet, Rule } from "../taskpane/components/RuleContentView/RuleContent";

enum RowType {
    RuleFile = "RuleFile",
    Sheet = "Sheet",
    Rule = "Rule",
}

export class RuleContentReader {
    private static readonly RULE_CONTENT_SHEET: string = "RULES"

    private constructor() {}

    public static create(): RuleContentReader {
        return new RuleContentReader();
    }

    public async readRuleFiles(): Promise<RuleFile[]> {
        return await Excel.run(async (context) => {
            const worksheets = context.workbook.worksheets;
            worksheets.load("items/name")
            await context.sync();

            const ruleContentWorksheet = context.workbook.worksheets.items.find((sheet) => {
                return RuleContentReader.RULE_CONTENT_SHEET === sheet.name
            });

            if (ruleContentWorksheet === null) {
                return [];
            }
            
            const ret = [];
            
            const range = ruleContentWorksheet.getUsedRange();
            range.load('values');
            await context.sync();

            const rows = range.values;
            let index = 0;
            let ruleFile: RuleFile;
            let ruleSheet: Sheet;
            while(index < rows.length) {
                const currentRow = rows[index];
                const rowType = this.parseRowType(currentRow);
                if (rowType === RowType.RuleFile) {
                    if (ruleFile) {
                        ret.push(ruleFile);
                        ruleFile.sheets.push(ruleSheet)
                    }
                    ruleFile = await this.readRuleFileRow(currentRow);
                } else if (rowType === RowType.Sheet) {
                    ruleSheet = await this.readSheetRow(currentRow);
                } else if (rowType == RowType.Rule) {
                    ruleSheet.rules.push(await this.readRuleRow(ruleSheet.header.length, currentRow));
                }
                index++;
            }
            ruleFile.sheets.push(ruleSheet);
            ret.push(ruleFile);

            return ret;
        })
    }

    private parseRowType(row: any[]): RowType {
        return RowType[row[0]];
    }

    private async readRuleFileRow(row: any[]): Promise<RuleFile> {
        const ruleFileId = row[1];
        return {
            id: ruleFileId,
            sheets: []
        }
    }

    private async readSheetRow(row: any[]): Promise<Sheet> {
        const ruleSheetId = row[1];
        const lastColumn = row.indexOf("");
        const header: string[] = row.slice(2, lastColumn);
        return {
            id: ruleSheetId,
            header: header,
            rules: []
        }
    }

    private async readRuleRow(numberOfColumns: number, row: any): Promise<Rule> {
        const ruleId = row[1];
        const content = row.slice(2, numberOfColumns + 2);
        return {
            id: ruleId,
            columns: content
        }
    }
}