import {RuleFileTypeStatistic} from "./RuleFileTypeStatistic";
import {RuleFileTypeStatisticUtil} from "./RuleFileTypeStatisticUtil";
import {RuleFileParser} from "./RuleFileParser";
import {RULE_FILE_DIFFS_COL_NAME} from "../hook/UseShadowReportInfo";

export class TaxContextAnalyzer {
    constructor(private readonly ruleFileTypeUtil: RuleFileTypeStatisticUtil, private readonly ruleFileParser: RuleFileParser) {}

    public static create(): TaxContextAnalyzer {
        return new TaxContextAnalyzer(new RuleFileTypeStatisticUtil(), new RuleFileParser());
    }

    public containsTaxContext(headers: string[]): boolean {
        return  headers.includes(RULE_FILE_DIFFS_COL_NAME);
    }

    public async extractRuleFileTypes(ruleFileTypeCells: string[]): Promise<RuleFileTypeStatistic[]> {
        const ruleFileTypesMap: Map<string, RuleFileTypeStatistic[]> = new Map<string, RuleFileTypeStatistic[]>();

        const ruleFileTypeStatistics = this.convertToRuleFileTypeStatistics(ruleFileTypeCells);

        for (const ruleFileTypeStatistic of ruleFileTypeStatistics) {
            const ruleFileKey = this.ruleFileParser.generateRuleFileKey(ruleFileTypeStatistic);
            if (!!ruleFileTypesMap.get(ruleFileKey)) {
                ruleFileTypesMap.get(ruleFileKey)!.push(ruleFileTypeStatistic)
            } else {
                ruleFileTypesMap.set(ruleFileKey, [ruleFileTypeStatistic]);
            }
        }

        return Array.from(ruleFileTypesMap.values()).map(ruleFileTypes => this.ruleFileTypeUtil.mergeRuleFileTypeStatistics(ruleFileTypes));
    }

    private convertToRuleFileTypeStatistics(ruleFileTypeCells: string[]): RuleFileTypeStatistic[] {
        return ruleFileTypeCells.slice(1, ruleFileTypeCells.length).flatMap(ruleFileTypeCell => {
            return ruleFileTypeCell.split(',')
        }).flatMap(ruleFileStr => {
            return this.ruleFileParser.extractRuleFileType(ruleFileStr);
        });
    }
}
