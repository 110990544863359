import * as React from "react";
import {useEffect} from "react";
import {publishAppLoadMetric} from "@amzn/tax-platform-console-metrics";
import Progress from "./Progress";
import {aliasStorageEntry} from "../../storage/StorageEntries";
import {LandingPage} from "./LandingPage/LandingPage";
import {Tabs} from "@amzn/awsui-components-react";
import {DownloadSnapshot} from "./SnapshotButton/DownloadSnapshot/DownloadSnapshot";
import {useShadowReportName} from "../../hook/UseShadowReportName";
import {TaxContextPanel} from "./TaxContextView/TaxContextPanel";
import {RuleContentPanel} from "./RuleContentView/RuleContentPanel";
import {useTaxContentPanel} from "./TaxContextView/useTaxContentPanel";
import {getPresignedUrl} from "./SnapshotButton/Utils";

/* global console, Excel, require  */


export interface AppProps {
    title: string;
    isOfficeInitialized: boolean;
}

export default function App(props: AppProps) {
    const {title, isOfficeInitialized} = props;
    const shadowReportName = useShadowReportName();
    const { hasTaxContextColumn } = useTaxContentPanel();

    useEffect(() => {
        const auth = async () => {
            // This API call is to retrieve Midway credentials
            await getPresignedUrl('');
            publishAppLoadMetric(await aliasStorageEntry.get() || "unknown");
        }

        auth().catch((error) => {
            console.log(`auth error: ${error}`)
        });
    }, [])


    if (!isOfficeInitialized) {
        return (
            <Progress
                title={title}
                logo={require("./../../../assets/logo-filled.png")}
                message="Please sideload your addin to see app body."
            />
        );
    }

    return (
        <Tabs tabs={[
            shadowReportName && {
                label: "Main",
                id: "main",
                content: <LandingPage shadowReportName={shadowReportName}/>,
            },
            {
                label: "Download",
                id: "download",
                content: <DownloadSnapshot/>,
            },
            hasTaxContextColumn && {
                label: 'Tax Context',
                id: 'tax-context',
                content: <TaxContextPanel/>,
            },
            {
                label: "Rule Content",
                id: "rule-content",
                content: <RuleContentPanel/>
            }
        ].filter(tab => tab)}
        />
    );
}
