import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Container, Form, SelectProps, SpaceBetween} from "@amzn/awsui-components-react";
import {Button, Header} from "@amzn/awsui-components-react/polaris";
import {
    DeploymentTypes,
    INITIAL_METADATA,
    setShadowReportMetadata,
    ShadowReportMetadataEntries,
    ShadowReportMetadataFormFields,
    ShadowReportRegions
} from "../../../storage/ShadowReportMetadataSheet";

import {useForm} from "react-hook-form";
import {object, string} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {FieldType, ShadowReportMetadataFormField} from "./ShadowReportMetadataFormField";
import {ShadowReportMetadataContext, ShadowReportMetadataEditingContext} from "../../../contexts/ShadowReportMetadataContext";
import {buildMetric} from "../../../metric/metric";
import {validateAndPublishMetric} from "@amzn/tax-platform-console-metrics";

const SUBMIT_ERROR_TEXT = "Shadow Report metadata assignment failed with the following error";
const VALIDATION_FAILED_TEXT = "Please ensure that all input requirements are met.";
const TEXT_INPUT_REGEX_ERROR_TEXT = "Please use safe characters specified at https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html#object-key-guidelines."

const YEAR_OPTIONS = [];
for (let year = new Date().getFullYear(); year >= 2000; year--) {
    YEAR_OPTIONS.push({
        label: year.toString(),
        value: year.toString(),
    });
}

const schema = object().shape({
    [ShadowReportMetadataEntries.ShadowReportYear]: string().required(),
    [ShadowReportMetadataEntries.DeploymentType]: string().required(),
    [ShadowReportMetadataEntries.DeploymentId]: string()
        .matches(/^[0-9a-zA-Z!\-_.*'()]*$/, TEXT_INPUT_REGEX_ERROR_TEXT)
        .min(1)
        .max(256)
        .required(),
    [ShadowReportMetadataEntries.ShadowReportRegion]: string().required(),
    [ShadowReportMetadataEntries.ShadowReportId]: string()
        .matches(/^[0-9a-zA-Z!\-_.*'()]*$/, TEXT_INPUT_REGEX_ERROR_TEXT)
        .min(1)
        .max(256)
        .required(),
})

const getRowCount = async () => {
    return await Excel.run(async (context) => {
        let originalSheet = context.workbook.worksheets.getFirst();
        await context.sync()

        let usedRange = originalSheet.getUsedRange();
        usedRange.load("rowCount");
        await context.sync();
        return usedRange.rowCount
    })
}

export function ShadowReportMetadataForm({shadowReportName}) {
    const {shadowReportMetadata, shadowReportMetadataFormFieldsAssigned} = useContext(ShadowReportMetadataContext);
    const {
        shadowReportMetadataEditing,
        setShadowReportMetadataEditing
    } = useContext(ShadowReportMetadataEditingContext);
    const [formErrorText, setFormErrorText] = useState("");
    const {control, formState: {errors}, reset, handleSubmit} = useForm({
        defaultValues: {
            ...INITIAL_METADATA
        },
        mode: "onBlur",
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (shadowReportMetadata) {
            const formFields = {};
            for (const field of ShadowReportMetadataFormFields) {
                const fieldValue = shadowReportMetadata[field];
                if (fieldValue) {
                    formFields[field] = typeof fieldValue === "string" ? fieldValue : fieldValue.toString();
                }
            }
            if (!formFields[ShadowReportMetadataEntries.ShadowReportId]) {
                const removeExtension = (filename) => {
                    return filename.substring(0, filename.lastIndexOf(".")) || filename;
                }
                formFields[ShadowReportMetadataEntries.ShadowReportId] = removeExtension(shadowReportName);
            }
            reset(formFields);
        }
    }, [shadowReportName, shadowReportMetadata]);

    const onSubmit = async (data) => {
        try {
            const metric = await buildMetric("ShadowReportMetadataAssignment", shadowReportMetadata);
            metric.addProperty("IsEditing", shadowReportMetadataEditing.toString());
            if (!shadowReportMetadataEditing) {
                metric.addProperty("RowCount", await getRowCount());
            }
            for (const field of ShadowReportMetadataFormFields) {
                if (data[field] !== shadowReportMetadata[field]) {
                    metric.addProperty(`New${field}`, data[field]);
                }
            }
            await setShadowReportMetadata({
                ...shadowReportMetadata,
                ...data,
            });
            validateAndPublishMetric(metric);
            setShadowReportMetadataEditing(false);
        } catch (e) {
            setFormErrorText(`${SUBMIT_ERROR_TEXT}: ${e.message}`);
        }
    }

    const onError = async (errors) => {
        const metric = await buildMetric("ShadowReportMetadataValidationErrors");
        for (const field in errors) {
            metric.addProperty(field, errors[field].message);
        }
        setFormErrorText(VALIDATION_FAILED_TEXT);
        validateAndPublishMetric(metric);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Form actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button disabled={!shadowReportMetadataFormFieldsAssigned} formAction="none"
                            onClick={() => setShadowReportMetadataEditing(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary">
                        Assign
                    </Button>
                </SpaceBetween>}
                  header={
                      <Header
                          variant="h3"
                          description="Please provide information about the Shadow Report before using add-in features."
                      >
                          Shadow Report Metadata Form
                      </Header>
                  }
                  errorText={formErrorText}
            >
                <Container>
                    <SpaceBetween direction="vertical" size={"s"}>
                        <ShadowReportMetadataFormField
                            inputType={FieldType.Select}
                            metadataField={ShadowReportMetadataEntries.ShadowReportYear}
                            errors={errors}
                            options={YEAR_OPTIONS}
                            control={control}
                        />
                        <ShadowReportMetadataFormField
                            inputType={FieldType.Select}
                            metadataField={ShadowReportMetadataEntries.DeploymentType}
                            errors={errors}
                            options={Object.values(DeploymentTypes).map(value => {
                                const option: SelectProps.Option = {
                                    label: value,
                                    value: value,
                                }
                                return option;
                            })}
                            control={control}
                        />
                        <ShadowReportMetadataFormField
                            inputType={FieldType.Input}
                            metadataField={ShadowReportMetadataEntries.DeploymentId}
                            errors={errors}
                            control={control}
                        />
                        <ShadowReportMetadataFormField
                            inputType={FieldType.Select}
                            metadataField={ShadowReportMetadataEntries.ShadowReportRegion}
                            errors={errors}
                            options={Object.values(ShadowReportRegions).map(value => {
                                const option: SelectProps.Option = {
                                    label: value,
                                    value: value,
                                }
                                return option;
                            })}
                            control={control}
                        />
                        <ShadowReportMetadataFormField
                            inputType={FieldType.Input}
                            metadataField={ShadowReportMetadataEntries.ShadowReportId}
                            errors={errors}
                            control={control}
                        />
                    </SpaceBetween>
                </Container>
            </Form>
        </form>
    )
}
