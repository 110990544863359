import * as React from "react";
import {FormField, SelectProps} from "@amzn/awsui-components-react";
import {
    ShadowReportMetadataEntries,
} from "../../../storage/ShadowReportMetadataSheet";
import {FieldErrors} from "react-hook-form";
import {ControlledInput, ControlledSelect} from "@amzn/react-hook-form-polaris";

const keyToDescriptionMap: { [key in ShadowReportMetadataEntries]: string } = {
    [ShadowReportMetadataEntries.ShadowReportYear]: "",
    [ShadowReportMetadataEntries.DeploymentType]: "What type of deployment are you reviewing?",
    [ShadowReportMetadataEntries.DeploymentId]: "What is the ID of the deployment you are reviewing?",
    [ShadowReportMetadataEntries.ShadowReportRegion]: "",
    [ShadowReportMetadataEntries.ShadowReportId]: "Please provide an ID for the Shadow Report.",
    [ShadowReportMetadataEntries.IsFinalized]: "",
}

const keyToLabelMap: { [key in ShadowReportMetadataEntries]: string } = {
    [ShadowReportMetadataEntries.ShadowReportYear]: "Shadow Report Year",
    [ShadowReportMetadataEntries.DeploymentType]: "Deployment Type",
    [ShadowReportMetadataEntries.DeploymentId]: "Deployment ID",
    [ShadowReportMetadataEntries.ShadowReportRegion]: "Shadow Report Region",
    [ShadowReportMetadataEntries.ShadowReportId]: "Shadow Report ID",
    [ShadowReportMetadataEntries.IsFinalized]: "",
}

export enum FieldType {
    Input,
    Select,
}

interface MetadataFormFieldProps {
    inputType: FieldType
    metadataField: ShadowReportMetadataEntries;
    errors: FieldErrors;
    options?: SelectProps.Options;
    control: any
}

export function ShadowReportMetadataFormField(props: MetadataFormFieldProps) {
    const {metadataField, errors, options, control, inputType} = props;

    let content;
    switch (inputType) {
        case FieldType.Input:
            content = <ControlledInput
                name={metadataField}
                control={control}
            />
            break;
        case FieldType.Select:
            content = <ControlledSelect
                name={metadataField}
                control={control}
                options={options}
            />
            break;
    }

    return (
        <FormField label={keyToLabelMap[metadataField]}
                   description={keyToDescriptionMap[metadataField]}
                   errorText={errors[metadataField]?.message}>
            {content}
        </FormField>
    );
}