import JSZip from 'jszip';
import ExcelJS from 'exceljs';

export const CONTEXT_SHEET_NAME = "CONTEXT"

export const HEADER_NAMES = {
    ruleFileDiffs: 'Rule File Diffs',
    ruleDiffs: 'Rule Diffs',
    tarIdentifier: 'TAR Identifier',
    trIdentifier: 'TR Identifier'
};

export async function downloadAndUnzipExcelFile(url: string): Promise<Blob> {
    try {
        console.log('start downloading');
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Download failed (HTTP ${response.status}): ${response.statusText}`);
        }
        console.log('downloading successful');

        const zipBlob = await response.blob();
        const zip = await JSZip.loadAsync(zipBlob);

        const xlsxFile = Object.keys(zip.files).find(name => name.endsWith('.xlsx'));

        if (!xlsxFile) {
            throw new Error('No Excel file found in ZIP archive');
        }

        return await zip.file(xlsxFile)!.async('blob');
    } catch (error) {
        throw new Error(`Failed to download and extract excel file: ${error.message}`);
    }
}

export async function extractRulesSheetAndContextSheet(url: string): Promise<{
    rulesSheet: ExcelJS.Worksheet,
    contextSheet: ExcelJS.Worksheet
}> {
    try {
        const excelBlob = await downloadAndUnzipExcelFile(url);
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(await excelBlob.arrayBuffer());

        const rulesSheet = workbook.getWorksheet('RULES');
        const contextSheet = workbook.getWorksheet('CONTEXT');

        if (!rulesSheet || !contextSheet) {
            throw new Error('Required sheets (RULES and/or CONTEXT) not found in workbook');
        }

        return { rulesSheet, contextSheet };
    } catch (error) {
        throw new Error(`Sheet extraction failed: ${error.message}`);
    }
}

export async function renameFirstWorksheet(name: string): Promise<void> {
    try {
        await Excel.run(async (context: Excel.RequestContext) => {
            const sheets = context.workbook.worksheets;
            sheets.load('items/name');
            await context.sync();

            if (sheets.items.some(sheet => sheet.name === name)) {
                return
            }

            const firstSheet = sheets.items[0];
            firstSheet.name = name;
            await context.sync();
        });
    } catch (error) {
        throw new Error(`Failed to rename worksheet: ${error.message}`);
    }
}

export async function getWorkSheetByName(context: Excel.RequestContext, name: string): Promise<Excel.Worksheet> {
    const sheets = context.workbook.worksheets;
    sheets.load('items/name');
    await context.sync();

    const sheetExits = sheets.items.some(sheet => sheet.name === name)
    if (!sheetExits) {
        throw new Error(`Sheet ${name} does not exist in the workbook.`);
    }

    const contextSheet = sheets.getItem(name);
    return contextSheet
}

export async function getHeaderRow(sheet: Excel.Worksheet, columnCount: number, context: Excel.RequestContext): Promise<string[]> {
    const headerRow = sheet.getRangeByIndexes(0, 0, 1, columnCount);
    headerRow.load('values');
    await context.sync();
    return headerRow.values[0];
}

export async function getUsedRange(sheet: Excel.Worksheet, context: Excel.RequestContext): Promise<Excel.Range> {
    const usedRange = sheet.getUsedRange(true);
    usedRange.load(['address', 'rowCount', 'columnCount']);
    await context.sync();
    return usedRange;
}

// Function to dynamically find column indices based on header names
export function findColumnIndices(headerRow: ExcelJS.Row): {
    ruleFileDiffs: number;
    ruleDiffs: number;
    tarIdentifier: number;
    trIdentifier: number;
} {
    const indices = {
        ruleFileDiffs: -1,
        ruleDiffs: -1,
        tarIdentifier: -1,
        trIdentifier: -1
    };

    headerRow.eachCell((cell, colNumber) => {
        const cellValue = cell.text?.trim().toLowerCase() ?? '';

        if (cellValue === HEADER_NAMES.ruleFileDiffs.toLowerCase()) {
            indices.ruleFileDiffs = colNumber;
        } else if (cellValue === HEADER_NAMES.ruleDiffs.toLowerCase()) {
            indices.ruleDiffs = colNumber;
        } else if (cellValue === HEADER_NAMES.tarIdentifier.toLowerCase()) {
            indices.tarIdentifier = colNumber;
        } else if (cellValue === HEADER_NAMES.trIdentifier.toLowerCase()) {
            indices.trIdentifier = colNumber;
        }
    });

    // Validate required columns were found
    if (indices.tarIdentifier === -1) {
        throw new Error(`Required column '${HEADER_NAMES.tarIdentifier}' not found in context sheet`);
    }
    if (indices.trIdentifier === -1) {
        throw new Error(`Required column '${HEADER_NAMES.trIdentifier}' not found in context sheet`);
    }

    return indices;
}