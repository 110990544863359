import ExcelJS from "exceljs";

const RULE_SHEET_NAME = "RULES"

export async function copyRulesSheetToWorkbook(sourceRulesSheet: ExcelJS.Worksheet) {
    if (!sourceRulesSheet) {
        throw new Error('Source rules sheet is empty');
    }

    try {
        await Excel.run(async (context: any) => {
            // Load the opening workbook
            const sheets = context.workbook.worksheets;
            sheets.load("items/name");
            await context.sync();

            // Check if the RULES sheet already exists or not
            let newSheet: Excel.Worksheet;
            const existingRulesSheet = sheets.items.find((sheet: any) => sheet.name === RULE_SHEET_NAME);

            if (existingRulesSheet) {
                // Clear existing RULES sheet
                existingRulesSheet.getUsedRange()?.clear();
                newSheet = existingRulesSheet;
            } else {
                // Create new RULES sheet
                newSheet = sheets.add(RULE_SHEET_NAME);
            }

            const rowCount = sourceRulesSheet.rowCount;
            const colCount = sourceRulesSheet.columnCount;

            console.log(`Rule Sheet dimensions: ${rowCount} rows x ${colCount} columns`);

            if (rowCount === 0 || colCount === 0) {
                console.info('Source rules sheet is empty')
                await context.sync(); // Sync before returning
                return;
            }

            const data: any[][] = Array(rowCount).fill(null)
                .map(() => Array(colCount).fill(''));

            // ExcelJS eachRow() use 1-based index
            sourceRulesSheet.eachRow((row, rowIndex) => {
                row.eachCell((cell, colIndex) => {
                    data[rowIndex - 1][colIndex - 1] = cell.value;
                });
            });

            const range = newSheet.getRangeByIndexes(0, 0, rowCount, colCount);
            range.values = data;

            await context.sync();
        });
    } catch (error) {
        console.error('Failed to update rules sheet:', error)
        throw error
    }
}
