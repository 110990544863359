export class ExcelClient {
    private static readonly TEMP_TABLE_NAME: string = "TEMP_TABLE_NAME"

    private constructor() {}

    public static create(): ExcelClient {
        return new ExcelClient();
    }

    public async getCellsDataByHeaderName(headerName: string): Promise<string[]> {
        const colIndex = await this.getHeaderIdxByName(headerName);

        const cellsData = await this.getCellsDataByColIDx(String.fromCharCode('A'.charCodeAt(0) + colIndex));
        return cellsData.slice(1, cellsData.length - 1);
    }

    public async getHeaders(): Promise<string[]> {
        const columnHeader = await Excel.run(async (context) => {
            const worksheet = context.workbook.worksheets.getFirst();
            const lastColumns = await this.getLastColumn(context, worksheet);
            const columnRange = worksheet.getRangeByIndexes(0, 0, 1, lastColumns);
            columnRange.load('values')
            await context.sync();

            return columnRange.values;
        }).catch((error) => {
            console.error(error);
        });

        return columnHeader[0];
    }

    public async filterColumnByString(headerName: string, subString: string): Promise<void> {
        await this.createTempTable();

        await Excel.run(async (context) => {
            const tables = context.workbook.worksheets.getFirst().tables;
            const filter = tables.getItem(ExcelClient.TEMP_TABLE_NAME).columns.getItem(headerName).filter;
            filter.applyCustomFilter(`*${subString}*`);

            await context.sync();
        }).catch(error => {
            console.error(error);
        });
    }

    public async sortByColumnHeader(headerName: string, ascending: boolean): Promise<void> {
        const headerIdx = await this.getHeaderIdxByName(headerName);
        await this.sortByColumn(headerIdx, ascending);
    }

    private async sortByColumn(columIdx: number, ascending: boolean): Promise<void> {
        await this.createTempTable();

        await Excel.run(async (context) => {
            const tables = context.workbook.worksheets.getFirst().tables;
            const sort = tables.getItem(ExcelClient.TEMP_TABLE_NAME).sort;

            sort.apply([{
                key: columIdx,
                ascending: ascending
            }]);

            await context.sync();
        }).catch(error => {
            console.error(error);
        });
    }

    private async getHeaderIdxByName(headerName: string): Promise<number> {
        const headers = await this.getHeaders();

        return headers.indexOf(headerName);
    }

    private async getCellsDataByColIDx(colIdx: string): Promise<string[]> {
        const columnValues = await Excel.run(async (context) => {
            const worksheet = context.workbook.worksheets.getFirst();
            const rowCount = await this.getUsedRowCount(context, worksheet);
            const columnRange = worksheet.getRange(`${colIdx}1:${colIdx}${rowCount}`);
            columnRange.load("values");
            await context.sync();

            return columnRange.values;
        });

        return this.convertColumnsToArray(columnValues);
    }

    private async createTempTable(): Promise<void> {
        await Excel.run(async (context) => {
            const worksheet = context.workbook.worksheets.getFirst();
            const tempTable = worksheet.tables.getItemOrNullObject(ExcelClient.TEMP_TABLE_NAME);
            await context.sync();
            if (!tempTable.isNullObject) {
                return;
            }

            const table = context.workbook.tables.add(worksheet.getUsedRange(), true);
            table.name = ExcelClient.TEMP_TABLE_NAME;

            await context.sync();
        }).catch(error => {
            console.error(error);
        });
    }

    private async getUsedRowCount(context: Excel.RequestContext, sheet: Excel.Worksheet): Promise<number> {
        const usedRange = sheet.getUsedRange();

        usedRange.load("rowCount");
        await context.sync();

        return usedRange.rowCount
    }

    private async getLastColumn(context: Excel.RequestContext, sheet: Excel.Worksheet): Promise<number> {
        const getLastColumn = sheet.getUsedRange().getLastColumn();
        getLastColumn.load("columnIndex");
        await context.sync();

        return getLastColumn.columnIndex;
    }

    private convertColumnsToArray(cells: string[][]): string[] {
        const elements: string[] = [];

        for (let i = 0; i < cells.length; i++) {
            const cell = cells[i][0];

            elements.push(cell)
        }

        return elements;
    }
}
