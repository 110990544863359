import {RuleFileTypeStatistic, RuleTypeCount, RuleTypeStatistic} from "./RuleFileTypeStatistic";
import {ClusteringResultType} from "../hook/UseShadowReportInfo";

type RuleTypeStatisticStr = string;

export class RuleFileTypeStatisticUtil {
    public mergeRuleFileTypeStatistics(ruleTypeStatistics: RuleFileTypeStatistic[]): RuleFileTypeStatistic {
        return ruleTypeStatistics.reduce((prevRuleTypeStatistic, currentRuleTypeStatistic) => ({
            stage: prevRuleTypeStatistic.stage, service: prevRuleTypeStatistic.service, ruleFileType: prevRuleTypeStatistic.ruleFileType,
            ruleStats: this.mergeRuleTypeStatistics([...prevRuleTypeStatistic.ruleStats, ...currentRuleTypeStatistic.ruleStats])
        }), {
            stage: ruleTypeStatistics[0].stage,
            service: ruleTypeStatistics[0].service,
            ruleFileType: ruleTypeStatistics[0].ruleFileType,
            ruleStats: [] as RuleTypeStatistic[]
        })
    }

    public getExistingClusters(headers: string[]): string[] {
        const clusterHeaders = Object.values(ClusteringResultType).map(clusteringResultHeader => `${clusteringResultHeader.toString()} Cluster`)

        return headers.filter(header => clusterHeaders.includes(header));
    }

    private mergeRuleTypeStatistics(ruleTypeStatistics: RuleTypeStatistic[]): RuleTypeStatistic[] {
        const mergedRuleTypeStatistics: Map<RuleTypeStatisticStr, RuleTypeCount> = new Map<RuleTypeStatisticStr, RuleTypeCount>();
        for (const ruleTypeStatistic of ruleTypeStatistics) {
            const ruleTypeStatisticKey = this.createRuleTypeStatisticKey(ruleTypeStatistic);
            if (!mergedRuleTypeStatistics.has(ruleTypeStatisticKey)) {
                mergedRuleTypeStatistics.set(ruleTypeStatisticKey, 0);
            }

            const mergedRuleTypeCount: RuleTypeCount = mergedRuleTypeStatistics.get(ruleTypeStatisticKey)!;
            mergedRuleTypeStatistics.set(ruleTypeStatisticKey, mergedRuleTypeCount + ruleTypeStatistic.count);
        }

        return Array.from(mergedRuleTypeStatistics.entries()).map(([ruleTypeStatisticKey, count])=> {
            const ruleTypeStatistic: RuleTypeStatistic = JSON.parse(ruleTypeStatisticKey);
            ruleTypeStatistic.count = count;

            return ruleTypeStatistic;
        });
    }

    private createRuleTypeStatisticKey(ruleTypeStatistic: RuleTypeStatistic): string {
        const ruleTypeStatisticKeyObject = {
            ...ruleTypeStatistic
        }

        delete ruleTypeStatisticKeyObject.count;

        return JSON.stringify(ruleTypeStatisticKeyObject)
    }
}
